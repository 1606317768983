/* eslint no-underscore-dangle: 0 */
import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';

import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { CategoryType } from 'types/breedManagement';

import { categoryHeadCells } from 'views/breedManagement/constants/variables';

import { ArrangementOrder } from 'types';

type CategoryListTableProps = {
    categories: CategoryType[];
    handleRequestSort: (event: React.SyntheticEvent<Element, Event>, property: string) => void;
    orderBy: string;
    order: ArrangementOrder;
    handleView: (id: string, category: CategoryType) => void;
};

const CategoryListTable = ({ categories, handleRequestSort, order, orderBy, handleView }: CategoryListTableProps) => {
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {categoryHeadCells.map((headCell) => {
                                if (headCell.id === 'action') {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                        >
                                            <Box component="span">{headCell.label}</Box>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {categories.length > 0 &&
                            categories.map((category) => (
                                <TableRow key={category._id}>
                                    <TableCell align="left">{category.name}</TableCell>

                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                style={{ padding: '5px 10px' }}
                                                onClick={() => handleView(category._id, category)}
                                            >
                                                {category.hasCategory ? 'View Subcategories' : 'View Breeds'}
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!categories.length && (
                    <Typography variant="h5" align="center" color="primary">
                        No data available
                    </Typography>
                )}
            </TableContainer>
        </>
    );
};

export default CategoryListTable;
