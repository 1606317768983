/* eslint no-underscore-dangle: 0 */
import {
    Button,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { BreedType } from 'types/breedManagement';
import { categoryHeadCells } from 'views/breedManagement/constants/variables';
import { IconEdit, IconDelete } from 'constants/icons';
import { useEffect, useState } from 'react';

type BreedListTableProps = {
    breeds: BreedType[];
    search: string;
    handleEditOpen: (breed: BreedType) => void;
    handleBreedDelete: (event: React.MouseEvent, id: string) => void;
};

const BreedListTable = ({ breeds, search, handleEditOpen, handleBreedDelete }: BreedListTableProps) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof BreedType>('name');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof BreedType) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        setPage(1);
    }, [search]);

    const filteredBreeds = breeds.filter((breed) => breed.name.toLowerCase().includes(search.toLowerCase()));

    const sortedBreeds = filteredBreeds.sort((a, b) => {
        if (orderBy === 'name') {
            return (a.name < b.name ? -1 : 1) * (order === 'asc' ? 1 : -1);
        }
        return 0;
    });

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedBreeds = sortedBreeds.slice(startIndex < 0 ? 0 : startIndex, endIndex);
    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {categoryHeadCells.map((headCell) => {
                                if (headCell.id === 'action') {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                        >
                                            <Box component="span">{headCell.label}</Box>
                                        </TableCell>
                                    );
                                }

                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, headCell.id as keyof BreedType)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {paginatedBreeds.length > 0 &&
                            paginatedBreeds.map((breed) => (
                                <TableRow key={breed._id}>
                                    <TableCell align="left">{breed.name}</TableCell>

                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                onClick={() => handleEditOpen(breed)}
                                            >
                                                <IconEdit />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                onClick={(event) => handleBreedDelete(event, breed._id)}
                                            >
                                                <IconDelete color="primary" />
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!breeds.length && (
                    <Typography variant="h5" align="center" color="primary">
                        No data available
                    </Typography>
                )}

                <Pagination
                    count={Math.ceil(filteredBreeds.length / rowsPerPage)}
                    color="primary"
                    shape="rounded"
                    onChange={handleChangePage}
                    page={page}
                    renderItem={(item) => <PaginationItem {...item} />}
                />
            </TableContainer>
        </>
    );
};

export default BreedListTable;
