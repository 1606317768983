import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    placeholder: string;
    size: 'medium' | 'small';
    adornmentPosition: 'start' | 'end';
    debouncedSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
};

export const Search = ({ placeholder, adornmentPosition, size, debouncedSearch, inputRef }: Props) => {
    return (
        <>
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={adornmentPosition}>
                            <SearchIcon sx={{ fontSize: '1rem' }} />
                        </InputAdornment>
                    )
                }}
                onChange={debouncedSearch}
                placeholder={placeholder}
                size={size}
                className="SearchField"
                inputRef={inputRef}
            />
        </>
    );
};
