import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    PaginationItem,
    Paper,
    Stack,
    TextField
} from '@mui/material';
import Spinner from 'components/spinner';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';
import { useEffect, useRef, useState } from 'react';
import useGQL from 'hooks/breedManagement/useGQL';

import MainCard from 'ui-component/cards/MainCard';
import { Search } from 'hooks/common/useSearch';
import FailureLoad from 'components/spinner/fail';
import { BreedType, CategoryType, LocationState } from 'types/breedManagement';
import { ArrangementOrder } from 'types';
import CategoryListTable from './tables';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'common/components/Breadcrumbs';
import { AnimalTypePaths } from 'common/paths';
import BreedListTable from './tables/breedTable';
import SubcategoryListTable from './tables/subCategoryTable';
import useSnackbar from 'hooks/common/useSnackbar';
import useAlertDialog from 'hooks/common/useAlertDialog';

const CategoryList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const { id: animalTypeId } = useParams();
    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();
    const state = (location.state as LocationState) ?? { hasCategory: false };
    const { hasCategory, animalType, categoryId, category, subCategoryId, subCategory } = state;
    const { GET_CATEGORY_BREED_LIST_QUERY, ADD_BREED_MUTATION, UPDATE_BREED_MUTATION, DELETE_BREED_MUTATION } = useGQL();
    const { error, loading, data, refetch } = GET_CATEGORY_BREED_LIST_QUERY({
        animalTypeId: animalTypeId ?? '',
        hasCategory: Boolean(hasCategory),
        categoryId: categoryId ?? '',
        subCategoryId: subCategoryId ?? ''
    });

    const [addBreed] = ADD_BREED_MUTATION();
    const [updateBreed] = UPDATE_BREED_MUTATION();
    const [deleteBreed] = DELETE_BREED_MUTATION();

    const [search, setSearchText] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>();
    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [count, setCount] = useState<number>(1);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [breeds, setBreeds] = useState<BreedType[]>([]);
    const [errorMessage, setErrorMessage] = useState('');

    // for breed only
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentBreed, setCurrentBreed] = useState<BreedType | null>(null);
    const [newBreedName, setNewBreedName] = useState('');
    const [catId, setCatId] = useState<string>('');

    const breadcrums: { label: string; path?: string }[] = [
        { label: 'Home', path: '/' },
        { label: 'Animal Type', path: AnimalTypePaths.LIST }
    ];

    if (categoryId && category) {
        breadcrums.push({
            label: animalType
        });

        if (subCategoryId) {
            breadcrums.push({
                label: category ?? ''
            });
            breadcrums.push({ label: `${subCategory} Breeds List` });
        } else {
            breadcrums.push({ label: `${category}` + `${hasCategory ? ' Subcategories List' : ' Breeds List'}` });
        }
    } else {
        breadcrums.push({
            label: `${animalType}` + `${hasCategory ? ' Categories List' : ' Breeds List'}`
        });
    }

    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event?.target) {
            setSearchText(event.target.value);
            setPage(0);
        }
    });

    useEffect(() => {
        if (data?.listCategoriesAndBreedsFromAnimalTypes) {
            if (hasCategory) {
                setBreeds([]);
                setCategories(data?.listCategoriesAndBreedsFromAnimalTypes?.categories || []);
                setCount(data?.listCategoriesAndBreedsFromAnimalTypes?.pagination?.total || 0);
            } else {
                setBreeds(data?.listCategoriesAndBreedsFromAnimalTypes?.breeds || []);
                setCount(0);
                setCategories([]);
                setCatId(data?.listCategoriesAndBreedsFromAnimalTypes?.categoryId ?? '');
            }
        }
    }, [data, hasCategory]);

    // **Centralized refetch handler**
    const handleRefetch = () => {
        refetch({
            input: {
                limit: pageMeta?.limit,
                skip: pageMeta?.skip,
                searchText: search,
                order,
                orderBy,
                animalTypeId: animalTypeId ?? '',
                hasCategory: Boolean(hasCategory),
                categoryId,
                subCategoryId
            }
        });
    };

    useEffect(() => {
        const limit = rowsPerPage;
        const skip = page - 1 > 0 ? limit * (page - 1) : 0;
        setPageMeta({ limit, skip });
        refetch({
            input: {
                searchText: search,
                limit,
                skip,
                order,
                orderBy,
                animalTypeId: animalTypeId ?? '',
                hasCategory: Boolean(hasCategory),
                categoryId,
                subCategoryId
            }
        });
    }, [rowsPerPage, page]);

    useEffect(() => {
        if (hasCategory) {
            handleRefetch();
        }
    }, [orderBy, order, search, hasCategory]);

    useEffect(() => {
        setPage(0);
        setSearchText('');
        setErrorMessage('');
        searchInputRef.current?.value && (searchInputRef.current.value = '');
    }, [state]);

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage === 0 ? newPage + 1 : newPage);
    };

    const handleClickOpen = () => {
        setIsEdit(false);
        setNewBreedName('');
        setOpen(true);
    };

    const handleEditOpen = (breed: BreedType) => {
        setIsEdit(true);
        setCurrentBreed(breed);
        setNewBreedName(breed.name);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentBreed(null);
        setErrorMessage('');
    };

    const handleView = (id: string, categoryData: CategoryType) => {
        let viewState;
        if (state.categoryId && !categoryData.hasCategory) {
            viewState = {
                hasCategory: categoryData.hasCategory,
                animalType: state.animalType,
                categoryId: state.categoryId,
                subCategoryId: categoryData._id,
                category: state.category,
                subCategory: categoryData.name
            };
        } else if (state.categoryId) {
            viewState = { hasCategory: categoryData.hasCategory, animalType: state.animalType, categoryId: categoryData._id };
        } else {
            viewState = {
                hasCategory: categoryData.hasCategory,
                animalType: state.animalType,
                categoryId: id,
                category: categoryData.name
            };
        }

        navigate(AnimalTypePaths.VIEW(animalTypeId), {
            state: viewState
        });
    };

    const handleAddOrEditBreed = async () => {
        if (!newBreedName) {
            setErrorMessage('Breed name is required');
            return;
        }
        if (!categoryId && !catId) {
            setErrorMessage('Category is required');
            return;
        }

        if (newBreedName.length < 3 || newBreedName.length > 30) {
            setErrorMessage('Breed name must be between 3 and 30 characters');
            return;
        }
        try {
            let response;
            if (isEdit && currentBreed) {
                response = await updateBreed({
                    variables: {
                        input: {
                            breedId: currentBreed._id,
                            name: newBreedName,
                            categoryId: categoryId ?? catId,
                            subCategoryId
                        }
                    }
                });
            } else {
                response = await addBreed({
                    variables: {
                        input: {
                            name: newBreedName,
                            categoryId: categoryId ?? catId,
                            subCategoryId
                        }
                    }
                });
            }

            handleOpenSnackbar({
                message: response?.data?.[isEdit ? 'updateBreed' : 'addBreed']?.message || 'Operation successful',
                alertType: 'success'
            });

            refetch();
            setOpen(false);
        } catch (er: any) {
            setErrorMessage(er.message);
        }
    };

    const handleBreedDelete = async (event: React.MouseEvent, id: string) => {
        const isConfirm = await getConfirmation({ title: 'Delete Breed', message: 'Are you sure you want to delete Breed ?' });
        if (isConfirm) {
            try {
                const response = await deleteBreed({
                    variables: { input: { breedId: id, categoryId: categoryId ?? catId, subCategoryId } }
                });
                handleOpenSnackbar({ message: response?.data?.deleteBreed?.message || 'Operation successful', alertType: 'success' });
                refetch();
            } catch (err: any) {
                handleOpenSnackbar({ message: err.message, alertType: 'error' });
            }
        }
    };

    if (error) return <FailureLoad />;

    const cardActions = (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center" spacing={2}>
            <Search
                size="small"
                placeholder="Search"
                adornmentPosition="start"
                debouncedSearch={debouncedSearch}
                inputRef={searchInputRef}
            />

            {!hasCategory && (
                <Button variant="contained" color="secondary" size="large" className="app-user-account-btn" onClick={handleClickOpen}>
                    Add Breed
                </Button>
            )}
        </Stack>
    );

    return (
        <>
            <Paper sx={{ px: 2, py: 1, mt: 0.5, mb: 2 }}>
                <Breadcrumbs items={breadcrums} />
            </Paper>
            <MainCard
                title={hasCategory ? (categoryId ? 'Subcategories List' : 'Categories List') : 'Breeds List'}
                content={false}
                secondary={cardActions}
            >
                <>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            {hasCategory && !categoryId ? (
                                <>
                                    <CategoryListTable {...{ categories, handleRequestSort, order, orderBy, handleView }} />

                                    <Pagination
                                        count={Math.ceil(count / rowsPerPage)}
                                        color="primary"
                                        shape="rounded"
                                        onChange={handleChangePage}
                                        page={page === 0 ? 1 : page}
                                        renderItem={(item) => <PaginationItem {...item} />}
                                    />
                                </>
                            ) : hasCategory ? (
                                <>
                                    <SubcategoryListTable {...{ subcategories: categories, search, handleView }} />
                                </>
                            ) : (
                                <BreedListTable {...{ breeds, search, handleEditOpen, handleBreedDelete }} />
                            )}
                        </>
                    )}
                </>
            </MainCard>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEdit ? 'Edit Breed' : 'Add Breed'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Breed Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newBreedName}
                        onChange={(e) => setNewBreedName(e.target.value)}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddOrEditBreed} color="primary">
                        {isEdit ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CategoryList;
