import { Link, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Breadcrumbs({ items }) {
    return (
        <div style={{ padding: '16px 0' }}>
            <MuiBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {items.map((item, index) => {
                    const isLast = index === items.length - 1;

                    return isLast ? (
                        <Typography key={item.label} color="text.primary">
                            {item.label}
                        </Typography>
                    ) : (
                        <Link key={item.label} underline="none" color="inherit" href={item.path}>
                            {item.label}
                        </Link>
                    );
                })}
            </MuiBreadcrumbs>
        </div>
    );
}

export default Breadcrumbs;
