import { useMutation, useQuery } from '@apollo/client';
import { ADD_BREED, DELETE_BREED, UPDATE_BREED } from 'grapqhl/breedManagement/mutations';
import { GET_ANIMAL_TYPE_LIST, GET_CATEGORY_BREED_LIST } from 'grapqhl/breedManagement/queries';
import {
    AnimalTypeListResponse,
    BreedCreateResponse,
    BreedDeleteResponse,
    BreedUpdateResponse,
    CategoryBreedListResponse,
    CreateBreedDto,
    DeleteBreedDto,
    GetAnimalTypeListDto,
    GetCategoryBreedListDto,
    UpdateBreedDto
} from 'types/breedManagement';

const useGQL = () => {
    const GET_ANIMAL_TYPE_LIST_QUERY = (input: GetAnimalTypeListDto = {}) =>
        useQuery<AnimalTypeListResponse>(GET_ANIMAL_TYPE_LIST, {
            variables: { input },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_CATEGORY_BREED_LIST_QUERY = (input: GetCategoryBreedListDto) =>
        useQuery<CategoryBreedListResponse>(GET_CATEGORY_BREED_LIST, {
            variables: { input },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const ADD_BREED_MUTATION = () => useMutation<BreedCreateResponse, { input: CreateBreedDto }>(ADD_BREED);

    const UPDATE_BREED_MUTATION = () => useMutation<BreedUpdateResponse, { input: UpdateBreedDto }>(UPDATE_BREED);

    const DELETE_BREED_MUTATION = () => useMutation<BreedDeleteResponse, { input: DeleteBreedDto }>(DELETE_BREED);

    return {
        GET_ANIMAL_TYPE_LIST_QUERY,
        GET_CATEGORY_BREED_LIST_QUERY,
        ADD_BREED_MUTATION,
        UPDATE_BREED_MUTATION,
        DELETE_BREED_MUTATION
    };
};

export default useGQL;
