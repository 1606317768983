import { gql } from '@apollo/client';

export const GET_ALL_APP_USERS = gql`
    query ($input: GetAppUsersDTO!) {
        getAllAppUsers(input: $input) {
            message
            users {
                _id
                authProvider
                authProviderId
                email
                mobile
                firstName
                lastName
                username
                about
                address {
                    fullAddress
                    state
                    suburb
                    postCode
                }
                location {
                    coordinates
                }
                createdAt
                updatedAt
                verified
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_APP_USER = gql`
    query ($id: String!) {
        getAppUser(id: $id) {
            message
            user {
                _id
                authProvider
                authProviderId
                firstName
                lastName
                username
                about
                email
                mobile
                status
                profileImage
                bannerImage
                address {
                    fullAddress
                    state
                    suburb
                    postCode
                }
                location {
                    coordinates
                }
                facebookLink
                instagramLink
                website
                googleStaticMapImage
                lastLoggedInAt
                createdAt
                updatedAt
                verified
                active
                totalCredit
                isParent
                clubOrAssociations
                clubId
            }
        }
    }
`;

export const GET_APP_SUB_USERS = gql`
    query ($id: String!) {
        GetAppSubUsers(id: $id) {
            message
            users {
                _id
                authProvider
                authProviderId
                profileImage
                email
                mobile
                firstName
                lastName
                username
                about
                address {
                    fullAddress
                    state
                    suburb
                    postCode
                }
                location {
                    coordinates
                }
                createdAt
                updatedAt
                verified
            }
        }
    }
`;
