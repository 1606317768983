import { Type } from '../views/forSale/constants/types';
import { useMutation, useQuery } from '@apollo/client';

import {
    FORGOT_PASSWORD,
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
    GET_ADMIN_LIST,
    GET_ADMIN as ADMIN,
    UPDATE_ADMIN as ADMIN_UPDATE,
    UPDATE_ADMIN_PROFILE_IMAGE as ADMIN_UPDATE_PROFILE_IMAGE,
    DELETE_ADMIN as ADMIN_DELETE,
    GET_USER_PROFILE,
    REFRESH_TOKEN,
    PRESIGNURL,
    CHANGE_PASSWORD,
    CREATE_PAGE as CREATE_PG,
    UPDATE_PAGE as UPDATE_PG,
    DELETE_PAGE as DELETE_PG,
    LIST_PAGE as GET_LIST_PAGE,
    LIST_PAGES as GET_LIST_PAGES,
    CREATE_MENU as CREATE_MENUS,
    LIST_MENUS as GET_LIST_MENUS,
    DELETE_MENU as MENU_DELETE,
    UPDATE_MENU as MENU_UPDATE,
    LIST_MENU as GET_LIST_MENU,
    RESET_PASSWORD_OTP_VERIFICATION,
    CLIENT_RESET_PASSWORD,
    RESEND_OTP_CODE as RESEND_OTP,
    VERIFY_RESET_TOKEN,
    GET_ANIMALS,
    GET_ALL_ANIMAL_DETAILS_VLA
} from '../grapqhl';
import { GET_ALL_APP_USERS, GET_APP_USER } from 'grapqhl/appUser/queries';

import {
    GET_ALL_ANIMAL_BREEDS,
    GET_ALL_ANIMAL_TYPES,
    GET_ALL_SALES_ANIMALS,
    GET_SALES_ANIMALS,
    ListCategoriesAndBreeds,
    ListNewAnimalTypeList,
    getUsersAnimals
} from 'grapqhl/forSale/queries';
import {
    GET_ALL_ACCOUNT_SUBSCRIPTIONS,
    GET_ACCOUNT_SUBSCRIPTION_DETAILS,
    GET_ACCOUNT_SUBSCRIPTION_DETAILS_BY_USER
} from 'grapqhl/accountSubscription/queries';

import { GET_ALL_REPORT_USERS, GET_REPORT_DETAILS } from 'grapqhl/reportForm/queries';
import { CREATE_SALES_ANIMALS, UPDATE_SALES_ANIMALS } from 'grapqhl/forSale/mutations';
import {
    GET_ALL_TRANSACTIONS_HISTORY,
    GET_ALL_TRANSACTIONS_HISTORY_BY_USER,
    GET_TRANSACTION_HISTORY_DETAILS
} from 'grapqhl/transactionHistory/queries';
import { GET_ANIMAL_TYPES } from 'grapqhl/stats/queries';
import {
    GET_ALL_NEW_USERS_AND_ANIMAL_SALES_STATS,
    GET_ANIMAL_BY_TYPE,
    GET_AVERAGE_BY_USER,
    GET_AVERAGE_TRANSACTION,
    GET_LIVE_USERS,
    GET_TOTAL_ANIMALS,
    GET_TOTAL_BREEDERS,
    GET_TOTAL_CLASSIFIED,
    GET_TOTAL_NEW_USER,
    GET_TOTAL_NO_ANIMAL_USER,
    GET_TOTAL_REVENUE,
    GET_TOTAL_SALES,
    GET_TOTAL_USER
} from 'grapqhl/dashboard/queries';
import { CREATE_ANIMALS, UPDATE_ANIMALS, USER_SUBSCRIBE, deleteAnimal } from 'grapqhl/animals/mutations';
import { GET_ALL_NOTIFICATIONS, GET_NOTIFICATION_COUNTS } from 'grapqhl/notification/queries';
import { SET_NOTIFICATION_READ } from 'grapqhl/notification/mutation';
import { GET_ALL_ACTIVITY_LOGS, GET_ALL_ACTIVITY_LOGS_DETAILS } from 'grapqhl/activityLogs/queries';
import { GET_ALL_PACKAGES } from 'grapqhl/packages/queries';
import { GET_ALL_STATES, GET_ALL_SUBURBS } from 'grapqhl/locationFilter/queries';
import { SubscriptionByAdminInput, UserResponse } from 'views/userManagement/appUsers/constants/types';
import { GET_ALL_ENQUIRY_TEMPLATES, GET_ENQUIRY_TEMPLATE } from 'grapqhl/enquiryTemplates/queries';
import { CREATE_ENQUIRY_TEMP, REMOVE_ENQUIRY_TEMP, UPDATE_ENQUIRY_TEMP } from 'grapqhl/enquiryTemplates/mutations';
import {
    GET_ANIMAL_SERVICE_LISTING,
    GET_ANIMAL_SERVICE_TEMPLATE,
    GET_SERVICE_ADS_AMOUNT,
    UPDATE_ANIMAL_SERVICE_TEMP,
    GET_SERVICE_DETAILS
} from 'grapqhl/service/queries';
import {
    GET_APP_LIVESTOCK,
    GET_APP_PIN_LIVESTOCK,
    GET_BREEDERS,
    GET_PETS_LIST,
    GET_PIN_BREEDS,
    PINNED_PETS
} from 'grapqhl/pinanimals/quries';
import {
    ADD_PIN_LIVESTOCK,
    CHANGE_LIVESTOCK_INDEX,
    CHANGE_PIN_BREED_INDEX,
    PIN_BREEDERS,
    SET_PIN_PETS,
    UNPINNED_PETS,
    UNPIN_BREEDERS,
    UNPIN_LIVESTOCK,
    UPDATE_PETS_INDEX
} from 'grapqhl/pinanimals/mutations';

type GetAdminListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    status?: string;
};

type GetPagesDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetMenusDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type Point = {
    coordinates: number[];
};

type GetAppUsersDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    verified?: any;
    location?: Point;
    radius?: number;
};

type GetAccountSubscriptionDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    filter?: string[];
    toDate?: Date | null;
    fromDate?: Date | null;
    userId?: string;
};

type GetTransactionHistoryListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    filter?: string[];
    toDate?: Date | null;
    fromDate?: Date | null;
    subscriptionPlanFilter?: string[];
    userId?: string;
};

type GetActivityLogsListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    filter?: string[];
    toDate?: Date | null;
    fromDate?: Date | null;
};

type NewAnimalSalesUserAndStatInput = {
    filterKeyword?: string;
    endDate?: Date | null;
    startDate?: Date | null;
};

type GetNotificationListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type PriceRange = {
    min: number;
    max: number;
};

type AgeRange = {
    min: number;
    minType: Type;
    max: number;
    maxType: Type;
};

type LocationModel = {
    coordinates: number[];
};

type GetReportUserListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    filter?: string[];
    toDate?: Date | null;
    fromDate?: Date | null;
};

type GetSalesAnimalListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    animalType?: string;
    animalCategory?: string;
    animalSubCategory?: string;
    breed?: string[];
    pureBred?: string;
    priceRange?: PriceRange;
    age?: AgeRange;
    gender?: string;
    desexed?: string;
    kind?: string;
    location?: LocationModel;
    radius?: number;
    geneticTraits?: string;
    suitedFor?: string;
    chickensBantam?: string;
    handRaised?: string;
};

type ListAnimalTypeInput = {
    type?: string;
};

type ListBreedInput = {
    type?: string;
    animalType?: string;
};

type ReportIdInput = {
    reportId?: string;
};

type NotificationIdInput = {
    notificationId?: string;
};

type ViewSalesDetailPageInput = {
    _id?: string;
    serviceId?: string | null;
};

type animalId = {
    animalId?: string;
};

type AccountSubscriptionIdInput = {
    accountSubscriptionId?: string;
};

type Media = {
    file?: string;
    caption?: string;
    isCover?: string;
};

type CreateOverallSalesAnimalInput = {
    userId?: string;
    media?: Media;
    listingName?: string;
    description?: string;
    price?: number;
    enquiryType?: string;
    enquiryValue?: string;
    showOnProfile?: boolean;
    category?: string;
    animalType?: string;
    animalCategory?: string;
    animalSubCategory?: string;
    breed?: string;
    desexed?: string;
    pureBred?: string;
    gender?: string;
    dateOfBirth?: string;
    microchipNumber?: string;
    picNumber?: string;
    identificationNumber?: string;
    fatherId?: string;
    fatherDetail?: FatherInput;
    motherId?: string;
    motherDetail?: MotherInput;
    adsDuration?: number;
    adsAmount?: number;
    suitedFor?: string;
    handRaised?: string;
    breederId?: string;
    chickensBantam?: string;
    geneticTraits?: string;
    enquiryPreference?: {
        enquiryType?: string;
        enquiryValue?: string;
    }[];
    animalTypeId?: string;
    animalCategoryId?: string;
    animalSubCategoryId?: string;
    breedId?: string;
};

type UpdateOverallSalesAnimalInput = {
    _id?: string;
    userId?: string;
    media?: Media;
    listingName?: string;
    description?: string;
    price?: number;
    enquiryType?: string;
    enquiryValue?: string;
    showOnProfile?: boolean;
    category?: string;
    animalType?: string;
    animalCategory?: string;
    animalSubCategory?: string;
    breed?: string;
    desexed?: string;
    pureBred?: string;
    gender?: string;
    dateOfBirth?: string;
    microchipNumber?: string;
    picNumber?: string;
    identificationNumber?: string;
    fatherId?: string;
    fatherDetail?: FatherInput;
    motherId?: string;
    motherDetail?: MotherInput;
    adsDuration?: number;
    adsAmount?: number;
    suitedFor?: string;
    handRaised?: string;
    breederId?: string;
    chickensBantam?: string;
    geneticTraits?: string;
    enquiryPreference?: {
        enquiryType?: string;
        enquiryValue?: string;
    }[];
    animalTypeId?: string;
    animalCategoryId?: string;
    animalSubCategoryId?: string;
    breedId?: string;
};

type FatherInput = {
    listingName: string;
    image: string;
};

type MotherInput = {
    listingName: string;
    image: string;
};

type AccountSubscriptionUserIdInput = {
    userId?: string;
};

type TransactionHistoryIdInput = {
    subscriptionId?: string;
};

type ActivityLogsIdInput = {
    activityLogsId?: string;
};

type ListAllCategoriesAndBreedsInput = {
    animalTypeId?: string;
    hasCategory?: boolean;
    categoryId?: string;
    categoryName?: string;
};

type CustomFields = {
    title?: string;
    detail?: string;
    files?: string[];
};

type UpdateNewAnimalInput = {
    _id?: string;
    userId?: string;
    media?: Media;
    listingName?: string;
    description?: string;
    price?: number;
    category?: string;
    animalType?: string;
    animalCategory?: string;
    animalSubCategory?: string;
    breed?: string;
    desexed?: string;
    pureBred?: string;
    gender?: string;
    dateOfBirth?: string;
    microchipNumber?: string;
    identificationNumber?: string;
    fatherId?: string;
    fatherDetail?: FatherInput;
    motherId?: string;
    motherDetail?: MotherInput;
    geneticReports?: string[];
    structuralReports?: string[];
    achievements?: string[];
    customFields?: CustomFields[];
    geneticTraits?: string;
    suitedFor?: string;
    chickensBantam?: string;
    handRaised?: string;
    breederId?: string;
    animalTypeId: string;
    animalCategoryId?: string;
    animalSubCategoryId?: string;
    breedId: string;
};

type CreateNewAnimalInput = {
    userId?: string;
    media?: Media;
    listingName?: string;
    description?: string;
    category?: string;
    animalType?: string;
    animalCategory?: string;
    animalSubCategory?: string;
    breed?: string;
    pureBred?: string;
    gender?: string;
    dateOfBirth?: string;
    microchipNumber?: string;
    identificationNumber?: string;
    fatherId?: string;
    fatherDetails?: FatherInput;
    motherId?: string;
    motherDetails?: MotherInput;
    geneticReports?: string[];
    structuralReports?: string[];
    achievements?: string[];
    customFields?: CustomFields[];
    geneticTraits?: string;
    suitedFor?: string;
    chickensBantam?: string;
    handRaised?: string;
    breederId?: string;
    animalTypeId?: string;
    animalCategoryId?: string;
    animalSubCategoryId?: string;
    breedId?: string;
};

type ViewAnimalDetailVlaInput = {
    _id?: string;
};

type ResetTokenDTO = {
    userId?: string;
    token?: string;
};

type ListStateInput = {
    state?: string;
    page?: number;
    limit?: number;
};

type ListSuburbsInput = {
    state?: string;
    suburbSearch?: string;
    page?: number;
    limit?: number;
};

type GetUsersAnimalsInput = {
    order?: string;
    orderBy?: string;
    skip?: number;
    limit?: number;
    userId?: string;
    gender?: string;
};

type DeleteAnimalInput = {
    _id?: string;
};

type GetAllEnquiryTemp = {
    order?: string;
    orderBy?: string;
    skip?: number;
    limit?: number;
    searchText?: string;
};

type DeleteEnquiryTempInput = {
    _id?: string;
};

type CreateFormTemplate = {
    name?: string;
    dynamicFields?: ExtraField[];
};

type ExtraField = {
    fieldName?: string;
    fieldType?: string;
    items?: string[];
};

type EditFormTemplate = {
    _id?: string;
    name?: string;
    dynamicFields?: ExtraField[];
};

type GetEnquiryTemp = {
    _id?: string;
};

type EditServiceFrom = {
    id?: string;
    price?: number;
    description?: string;
    showOnProfile?: boolean;
    enquiryPreference?: {
        type?: string;
        value?: string;
    }[];
};

type GetPinAnimalsInput = {
    order?: string;
    orderBy?: string;
    skip?: number;
    limit?: number;
};
type GetDashboardFilterType = {
    startDate?: string | null;
    endDate?: string | null;
    rangeType?: string;
};

const useGQL = () => {
    const ADMIN_LOGIN = () => useMutation(LOGIN);
    const ADMIN_REGISTER = () => useMutation(REGISTER);
    const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
    const RESET_PASS = () => useMutation(RESET_PASSWORD);
    const CLIENT_RES_PASS = () => useMutation(CLIENT_RESET_PASSWORD);
    const RESET_PASS_OTP_VERIFICATION = () => useMutation(RESET_PASSWORD_OTP_VERIFICATION);
    const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);
    const RESEND_OTP_CODE = () => useMutation(RESEND_OTP);
    const VERIFY_RESET_PASSWORD_TOKEN = (body: ResetTokenDTO = {}) => useMutation(VERIFY_RESET_TOKEN);

    /* admins */
    const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) => useQuery(GET_ADMIN_LIST, { variables: { input } });
    const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
    const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
    const UPDATE_ADMIN_PROFILE_IMAGE = () => useMutation(ADMIN_UPDATE_PROFILE_IMAGE);

    const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
    const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE, { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' });
    const GET_ACCESS_TOKEN_FROM_REFRESH_TOKEN = (input) =>
        useMutation(REFRESH_TOKEN, { variables: input, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const PUTPRESIGNURL = () => useMutation(PRESIGNURL);

    const CREATE_PAGE = () => useMutation(CREATE_PG);
    // const UPDATE_PAGE = () => useMutation(UPDATE_PG, { refetchQueries: [{ query: GET_LIST_PAGES, variables: { input: {} } }] });
    const UPDATE_PAGE = () => useMutation(UPDATE_PG);
    const DELETE_PAGE = () => useMutation(DELETE_PG);
    const LIST_PAGES = (input: GetPagesDTO = {}) =>
        useQuery(GET_LIST_PAGES, {
            variables: { input },
            notifyOnNetworkStatusChange: true
        });
    const LIST_PAGE = (id: String) => useQuery(GET_LIST_PAGE, { variables: { id } });

    const CREATE_MENU = () => useMutation(CREATE_MENUS);
    const LIST_MENUS = (input: GetMenusDTO = {}) =>
        useQuery(GET_LIST_MENUS, {
            variables: { input },
            notifyOnNetworkStatusChange: true
        });
    const DELETE_MENU = () => useMutation(MENU_DELETE);
    const LIST_MENU = (id: String) => useQuery(GET_LIST_MENU, { variables: { id } });
    const UPDATE_MENU = () => useMutation(MENU_UPDATE);

    /* app-users */
    const GET_ALL_APPUSERS = (input: GetAppUsersDTO = {}) => useQuery(GET_ALL_APP_USERS, { variables: { input } });

    /* sales-animals */
    const GET_ALL_SALES_ANIMAL = (input: GetSalesAnimalListDTO = {}) => useQuery(GET_ALL_SALES_ANIMALS, { variables: { input } });
    const GET_ALL_ANIMAL_TYPE = (input: ListAnimalTypeInput = {}) =>
        useQuery(GET_ALL_ANIMAL_TYPES, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const GET_ALL_ANIMAL_BREED = (input: ListBreedInput = {}) =>
        useQuery(GET_ALL_ANIMAL_BREEDS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const GET_SALES_ANIMAL = (input: ViewSalesDetailPageInput = {}) =>
        useQuery(GET_SALES_ANIMALS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const CREATE_SALES_ANIMAL = (input: CreateOverallSalesAnimalInput = {}) =>
        useMutation(CREATE_SALES_ANIMALS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const UPDATE_SALES_ANIMAL = (input: UpdateOverallSalesAnimalInput = {}) =>
        useMutation(UPDATE_SALES_ANIMALS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

    /* report-users */
    const GET_ALL_REPORT_USER = (input: GetReportUserListDTO = {}) => useQuery(GET_ALL_REPORT_USERS, { variables: { input } });
    const GET_REPORT_DETAIL = (input: ReportIdInput = {}) => useQuery(GET_REPORT_DETAILS, { variables: { input } });
    const GET_ALL_ACCOUNT_SUBSCRIPTION = (input: GetAccountSubscriptionDTO = {}) =>
        useQuery(GET_ALL_ACCOUNT_SUBSCRIPTIONS, { variables: { input } });
    const GET_ACCOUNT_SUBSCRIPTION_DETAIL = (input: AccountSubscriptionIdInput = {}) =>
        useQuery(GET_ACCOUNT_SUBSCRIPTION_DETAILS, { variables: { input } });

    const GET_ACCOUNT_SUBSCRIPTION_DETAIL_BY_USER = (input: AccountSubscriptionUserIdInput = {}) =>
        useQuery(GET_ACCOUNT_SUBSCRIPTION_DETAILS_BY_USER, { variables: { input } });
    const GET_ALL_TRANSACTION_HISTORY = (input: GetTransactionHistoryListDTO = {}) =>
        useQuery(GET_ALL_TRANSACTIONS_HISTORY, { variables: { input } });

    const GET_ALL_TRANSACTION_HISTORY_BY_USER = (input: GetTransactionHistoryListDTO = {}) =>
        useQuery(GET_ALL_TRANSACTIONS_HISTORY_BY_USER, { variables: { input } });

    const GET_TRANSACTION_HISTORY_DETAIL = (input: TransactionHistoryIdInput = {}) =>
        useQuery(GET_TRANSACTION_HISTORY_DETAILS, { variables: { input } });

    const GET_LIST_NEW_ANIMAL_TYPE = () => useQuery(ListNewAnimalTypeList);

    const GET_LIST_CATEGORIES_AND_BREEDS = (input: ListAllCategoriesAndBreedsInput = {}) =>
        useQuery(ListCategoriesAndBreeds, { variables: { input } });
    const GET_ANIMAL_TYPE = () => useQuery(GET_ANIMAL_TYPES);

    const GET_ALL_NEW_USERS_AND_ANIMAL_SALES_STAT = (input: NewAnimalSalesUserAndStatInput = {}) =>
        useQuery(GET_ALL_NEW_USERS_AND_ANIMAL_SALES_STATS, { variables: { input } });

    const SUBSCRIBE_USER = (input: SubscriptionByAdminInput) =>
        useMutation(USER_SUBSCRIBE, {
            variables: { input },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    /* animals */
    const CREATE_ANIMAL = (input?: CreateNewAnimalInput) =>
        useMutation(CREATE_ANIMALS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const UPDATE_ANIMAL = (input?: UpdateNewAnimalInput) =>
        useMutation(UPDATE_ANIMALS, { variables: { input }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const GET_ALL_NOTIFICATION = (input: GetNotificationListDTO = {}) => useQuery(GET_ALL_NOTIFICATIONS, { variables: { input } });
    const GET_NOTIFICATION_COUNT = () => useQuery(GET_NOTIFICATION_COUNTS);
    const SET_NOTIFICATIONS_READ = (input: NotificationIdInput = {}) => useQuery(SET_NOTIFICATION_READ, { variables: { input } });
    const GET_ALL_ACTIVITY_LOG = (input: GetActivityLogsListDTO = {}) => useQuery(GET_ALL_ACTIVITY_LOGS, { variables: { input } });
    const GET_ALL_ACTIVITY_LOGS_DETAIL = (input: ActivityLogsIdInput = {}) =>
        useQuery(GET_ALL_ACTIVITY_LOGS_DETAILS, { variables: { input } });
    const GET_ALL_ANIMAL_DETAILS_VLA_LIST = (input: ViewAnimalDetailVlaInput = {}) =>
        useQuery(GET_ALL_ANIMAL_DETAILS_VLA, { variables: { input } });
    const GET_ALL_PACKAGE = () => useQuery(GET_ALL_PACKAGES);
    const GET_ANIMAL = (animalId: String) =>
        useQuery(GET_ANIMALS, { variables: { animalId }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
    const GET_USER_ANIMALS = (input: GetUsersAnimalsInput = {}) => useQuery(getUsersAnimals, { variables: { input } });
    const DELETE_ANIMAL = (deleteAnimalInput: DeleteAnimalInput = {}) => useMutation(deleteAnimal, { variables: { deleteAnimalInput } });

    /* location filter */
    const GET_ALL_STATE = (listStatesInput: ListStateInput = {}) => useQuery(GET_ALL_STATES, { variables: { listStatesInput } });
    const GET_ALL_SUBURB = (listSuburbsInput: ListSuburbsInput = {}) => useQuery(GET_ALL_SUBURBS, { variables: { listSuburbsInput } });

    /* enquiry-templates */
    const GET_ENQUIURY_TEMPLATES = (input: GetAllEnquiryTemp = {}) =>
        useQuery(GET_ALL_ENQUIRY_TEMPLATES, {
            variables: { input },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const DELETE_ENQUIRY_TEMP = (input: DeleteEnquiryTempInput = {}) => useMutation(REMOVE_ENQUIRY_TEMP, { variables: { input } });
    const ADD_ENQUIRY_TEMP = (input: CreateFormTemplate = {}) => useMutation(CREATE_ENQUIRY_TEMP, { variables: { input } });
    const EDIT_ENQUIRY_TEMP = (input: EditFormTemplate = {}) => useMutation(UPDATE_ENQUIRY_TEMP, { variables: { input } });
    const GET_ENQUIURY_TEMP = (input: GetEnquiryTemp = {}) =>
        useQuery(GET_ENQUIRY_TEMPLATE, {
            variables: { input },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_ANIMAL_SERVICE = (id: string) =>
        useQuery(GET_ANIMAL_SERVICE_TEMPLATE, {
            variables: { id },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const UPDATE_ANIMAL_SERVICE = (input: EditServiceFrom = {}) =>
        useMutation(UPDATE_ANIMAL_SERVICE_TEMP, {
            // eslint-disable-next-line no-underscore-dangle
            variables: { input, id: input.id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });
    const GET_USER = (id: string) =>
        useQuery<UserResponse, { id: string }>(GET_APP_USER, {
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const GET_SERVICE_LISTING = (serviceId: string) =>
        useQuery(GET_ANIMAL_SERVICE_LISTING, {
            variables: { serviceId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_SERVICE_ADS_PRICE_QUERY = (name?: string) =>
        useQuery(GET_SERVICE_ADS_AMOUNT, {
            variables: { name },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_PIN_BREEDERS_QUERY = (input?: GetPinAnimalsInput) =>
        useQuery(GET_PIN_BREEDS, {
            variables: {
                input
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });
    const GET_TOTAL_USER_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_USER, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const CHANGE_PIN_BREED_INDEX_MUTATION = (id?: string, index?: number) =>
        useMutation(CHANGE_PIN_BREED_INDEX, {
            variables: { id, index },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const GET_BREEDERS_QUERY = (input?: GetAppUsersDTO) =>
        useQuery(GET_BREEDERS, {
            variables: {
                input: {
                    search: input?.searchText,
                    orderBy: input?.orderBy,
                    order: input?.order,
                    limit: input?.limit,
                    skip: input?.skip,
                    verified: input?.verified,
                    location: input?.location
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const ADD_BREEDERS_MUTATION = (id?: string) =>
        useMutation(PIN_BREEDERS, { variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

    const UNPIN_BREEDERS_MUTATION = (id?: string) =>
        useMutation(UNPIN_BREEDERS, { variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

    const PINNED_PETS_LIST = (input?: GetPinAnimalsInput) =>
        useQuery(PINNED_PETS, {
            variables: {
                input: {
                    orderBy: input?.orderBy,
                    order: input?.order,
                    limit: input?.limit,
                    skip: input?.skip
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const GET_PETS_LIST_QUERY = (input?: GetSalesAnimalListDTO) =>
        useQuery(GET_PETS_LIST, {
            variables: { input },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const UPDATE_PETS_INDEX_MUTATION = (id?: string, index?: number) =>
        useMutation(UPDATE_PETS_INDEX, {
            variables: { input: { id, index } },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const UNPIN_PETS_MUTATION = (id?: string) =>
        useMutation(UNPINNED_PETS, {
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const SET_PIN_PETS_MUTATION = (id?: string) =>
        useMutation(SET_PIN_PETS, {
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const GET_APP_LIVESTOCK_QUERY = (input?: GetSalesAnimalListDTO) =>
        useQuery(GET_APP_LIVESTOCK, {
            variables: { input },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const GET_APP_PIN_LIVESTOCK_QUERY = (input?: GetPinAnimalsInput) =>
        useQuery(GET_APP_PIN_LIVESTOCK, {
            variables: {
                input: {
                    orderBy: input?.orderBy,
                    order: input?.order,
                    limit: input?.limit,
                    skip: input?.skip
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const CHANGE_LIVESTOCK_INDEX_MUTATION = (id?: string, index?: number) =>
        useMutation(CHANGE_LIVESTOCK_INDEX, {
            variables: { input: { id, index } },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const UNPIN_LIVESTOCK_MUTATION = (id?: string) =>
        useMutation(UNPIN_LIVESTOCK, {
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });

    const SET_PIN_LIVESTOCK_MUTATION = (id?: string) =>
        useMutation(ADD_PIN_LIVESTOCK, {
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        });
    const GET_TOTAL_NEW_USER_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_NEW_USER, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });
    const GET_TOTAL_BREEDERS_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_BREEDERS, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_TOTAL_NO_ANIMAL_USER_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_NO_ANIMAL_USER, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_TOTAL_ANIMAL_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_ANIMALS, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_ANIMAL_BY_TYPE_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_ANIMAL_BY_TYPE, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_TOTAL_REVENUE_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_REVENUE, {
            variables: {
                filter: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_ANIMAL_BY_CLASSIFIED_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_CLASSIFIED, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_TOTAL_SALES_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_TOTAL_SALES, {
            variables: {
                filter: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_AVERAGE_TRANSACTION_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_AVERAGE_TRANSACTION, {
            variables: {
                filter: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_AVERAGE_BY_USER_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_AVERAGE_BY_USER, {
            variables: {
                filter: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_LIVE_USER_QUERY = (input?: GetDashboardFilterType) =>
        useQuery(GET_LIVE_USERS, {
            variables: {
                input: { startDate: input?.startDate, endDate: input?.endDate, rangeType: input?.rangeType }
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    const GET_SERVICE_DETAILS_QUERY = (serviceId?: String) =>
        useQuery(GET_SERVICE_DETAILS, {
            variables: {
                serviceId
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
        });

    return {
        ADMIN_LOGIN,
        ADMIN_REGISTER,
        FORGOT_PASS,
        RESET_PASS,
        RESET_PASS_OTP_VERIFICATION,
        GET_ALL_ADMINS,
        GET_ADMIN,
        UPDATE_ADMIN,
        UPDATE_ADMIN_PROFILE_IMAGE,
        DELETE_ADMIN,
        GET_ADMIN_PROFILE,
        PUTPRESIGNURL,
        ADMIN_CHANGE_PASSWORD,
        CREATE_PAGE,
        UPDATE_PAGE,
        DELETE_PAGE,
        LIST_PAGES,
        LIST_PAGE,
        CREATE_MENU,
        LIST_MENUS,
        DELETE_MENU,
        LIST_MENU,
        CLIENT_RES_PASS,
        UPDATE_MENU,
        RESEND_OTP_CODE,
        VERIFY_RESET_PASSWORD_TOKEN,
        GET_ALL_APPUSERS,
        GET_ALL_SALES_ANIMAL,
        GET_ALL_ANIMAL_TYPE,
        GET_ALL_ANIMAL_BREED,
        GET_ALL_REPORT_USER,
        GET_REPORT_DETAIL,
        GET_SALES_ANIMAL,
        GET_ALL_ACCOUNT_SUBSCRIPTION,
        GET_ACCOUNT_SUBSCRIPTION_DETAIL,
        CREATE_SALES_ANIMAL,
        UPDATE_SALES_ANIMAL,
        GET_ACCOUNT_SUBSCRIPTION_DETAIL_BY_USER,
        GET_ALL_TRANSACTION_HISTORY,
        GET_ALL_TRANSACTION_HISTORY_BY_USER,
        GET_TRANSACTION_HISTORY_DETAIL,
        GET_LIST_NEW_ANIMAL_TYPE,
        GET_LIST_CATEGORIES_AND_BREEDS,
        GET_ANIMAL_TYPE,
        GET_ALL_NEW_USERS_AND_ANIMAL_SALES_STAT,
        UPDATE_ANIMAL,
        CREATE_ANIMAL,
        GET_ALL_NOTIFICATION,
        GET_NOTIFICATION_COUNT,
        SET_NOTIFICATIONS_READ,
        GET_ALL_ACTIVITY_LOG,
        GET_ALL_ACTIVITY_LOGS_DETAIL,
        GET_ALL_PACKAGE,
        GET_ANIMAL,
        GET_ALL_ANIMAL_DETAILS_VLA_LIST,
        GET_ALL_STATE,
        GET_ALL_SUBURB,
        GET_USER_ANIMALS,
        DELETE_ANIMAL,
        SUBSCRIBE_USER,
        GET_ENQUIURY_TEMPLATES,
        DELETE_ENQUIRY_TEMP,
        ADD_ENQUIRY_TEMP,
        EDIT_ENQUIRY_TEMP,
        GET_ENQUIURY_TEMP,
        GET_ANIMAL_SERVICE,
        UPDATE_ANIMAL_SERVICE,
        GET_USER,
        GET_SERVICE_LISTING,
        GET_SERVICE_ADS_PRICE_QUERY,
        GET_PIN_BREEDERS_QUERY,
        CHANGE_PIN_BREED_INDEX_MUTATION,
        GET_BREEDERS_QUERY,
        ADD_BREEDERS_MUTATION,
        UNPIN_BREEDERS_MUTATION,
        PINNED_PETS_LIST,
        GET_PETS_LIST_QUERY,
        UPDATE_PETS_INDEX_MUTATION,
        UNPIN_PETS_MUTATION,
        SET_PIN_PETS_MUTATION,
        // livestock
        GET_APP_LIVESTOCK_QUERY,
        GET_APP_PIN_LIVESTOCK_QUERY,
        CHANGE_LIVESTOCK_INDEX_MUTATION,
        UNPIN_LIVESTOCK_MUTATION,
        SET_PIN_LIVESTOCK_MUTATION,
        GET_TOTAL_USER_QUERY,
        GET_TOTAL_NEW_USER_QUERY,
        GET_TOTAL_BREEDERS_QUERY,
        GET_TOTAL_NO_ANIMAL_USER_QUERY,
        GET_TOTAL_ANIMAL_QUERY,
        GET_ANIMAL_BY_TYPE_QUERY,
        GET_TOTAL_REVENUE_QUERY,
        // classified
        GET_ANIMAL_BY_CLASSIFIED_QUERY,
        GET_TOTAL_SALES_QUERY,
        GET_AVERAGE_TRANSACTION_QUERY,
        GET_AVERAGE_BY_USER_QUERY,
        GET_LIVE_USER_QUERY,
        GET_SERVICE_DETAILS_QUERY,
        GET_ACCESS_TOKEN_FROM_REFRESH_TOKEN
    };
};

export default useGQL;
