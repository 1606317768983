import { gql } from '@apollo/client';

export const GET_ANIMAL_TYPE_LIST = gql`
    query ListAnimalTypesPagination($input: GetAnimalTypesDTO!) {
        listAnimalTypesPagination(input: $input) {
            animalTypes {
                _id
                category
                hasCategory
                name
            }
            message
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_CATEGORY_BREED_LIST = gql`
    query ListCategoriesAndBreedsFromAnimalTypes($input: GetCategoriesDTO!) {
        listCategoriesAndBreedsFromAnimalTypes(input: $input) {
            message
            pagination {
                total
                hasNextPage
            }
            categories {
                _id
                animalTypeId
                name
                hasCategory
                category
            }
            breeds {
                _id
                name
            }
            categoryId
        }
    }
`;
