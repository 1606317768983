/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
// material-ui
import {
    Checkbox,
    Divider,
    Grid,
    TextField,
    FormControlLabel,
    FormHelperText,
    Box,
    Button,
    FormLabel,
    Typography,
    FormControl,
    makeStyles,
    Radio,
    RadioGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    Input,
    Stack,
    Paper,
    Link,
    ImageList,
    ImageListItem,
    Card,
    CardMedia,
    OutlinedInput,
    Autocomplete,
    CircularProgress,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import Avatar from '@mui/material/Avatar';
import { Formik, Form, Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import useGQL from 'hooks/useGQL';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Snackbar from 'ui-component/extended/Snackbar';
import { RootState } from 'store';
// import { RegisterType, userRole as role } from '../constants/types';
import styled from 'styled-components';
import { ImageWrapper, IconButtonWrapper } from 'components/formik/formik.styles';
import invariant from 'tiny-invariant';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import placeHolder from 'assets/images/imagePlaceholder.jpg';

import { defaultImageConfig } from 'config';

import useImageUpload from 'hooks/common/useImageUpload';
import { AddSalesAnimals, showOnProfile } from '../constants/types';
import Dropdown from '../dropdown';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import dayjs, { Dayjs } from 'dayjs';
import { addOneDay, subtractOneDay } from 'utils/date.manipulation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { initial } from 'lodash';
import { useDialog } from 'views/userManagement/admins/hooks/useDialogs';
import AddLineage from './add-lineage';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ImageUploadDialog from 'hooks/common/useImageUploadDialog';
import SelectUserList from 'components/appUser/SelectUserList';
import AnimalDialog from '../hooks/AnimalDialog';
import { IconDismissSquare } from 'constants/icons';
import { AppUser } from 'views/userManagement/appUsers/constants/types';
import { useMutation } from '@apollo/client';
import { CREATE_SALES_ANIMALS, UPDATE_SALES_ANIMALS } from 'grapqhl/forSale/mutations';
import { textTransform } from '@mui/system';
import { PhoneInput } from 'react-international-phone';
// eslint-disable-next-line import/no-unresolved
import 'react-international-phone/style.css';
import { v4 as uuidv4 } from 'uuid';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrow';
import VideoDialog from 'hooks/common/useVideoDialog';
import { getVideoCover } from 'hooks/common/useVideoImage';
import { ContactPreference } from 'ui-component/form/multi-select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
const CHARACTER_LIMIT = 500;

const Image = ({ imagePlaceholder, imageSet, handleReset }) => (
    <ImageWrapper>
        <IconButtonWrapper imageSet={imageSet} onClick={handleReset}>
            <CloseIcon />
        </IconButtonWrapper>
        <Avatar src={imagePlaceholder} sx={{ width: 80, height: 80 }} />
    </ImageWrapper>
);
const categoryOptions = [
    { label: 'Pets', value: 'PETS' },
    { label: 'Livestocks', value: 'LIVESTOCKS' }
];

const genderOptions = [
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
    { label: 'Any', value: 'ANY' }
];

const desexedOptions = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' }
];

const enquiryOptions = [
    { label: 'Enquire In App', value: 'ENQUIRE_IN_APP' },
    { label: 'Text', value: 'TEXT' },
    { label: 'Phone', value: 'PHONE' },
    { label: 'Email', value: 'EMAIL' }
];

const pureBredOptions = [
    { label: 'Purebred', value: 'PUREBRED' },
    { label: 'Crossbred', value: 'CROSSBRED' },
    { label: 'N/A', value: 'NA' }
];

const handRaisedOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
];

type Pricing = {
    month: number;
    price: number;
};

interface AnimalTypesValues {
    _id: string;
    hasCategory: boolean;
    name: string;
}

const EditSalesAnimal = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [selectUser, setSelectUser] = useState<AppUser | null>(null);
    const { open } = useSelector((state: RootState) => state.snackbar);
    const [animalType, setAnimalType] = useState(false);
    const [animalCategory, setAnimalCategory] = useState(false);
    const [animalSubCategory, setAnimalSubCategory] = useState(false);
    const [animalBreed, setAnimalBreed] = useState(false);

    const [pricing, setPricing] = useState<Pricing>({ month: 0, price: 0 });
    const [pricingList, setPricingList] = useState<Pricing[]>([]);
    const [extraFields, setExtraFields] = useState<any>([]);
    const [suitedForList, setSuitedForList] = useState([]);
    const [chickensBantamList, setChickensBantamList] = useState([]);
    const [geneticTraits, setgeneticTraitsList] = useState([]);
    const [chosenAnimalType, setChosenAnimalType] = useState<any>({});
    const [chosenCategory, setChosenCategory] = useState<any>({});
    const [chosenSubCategory, setChosenSubCategory] = useState<any>({});
    const [breedList, setBreedList] = useState<any>([]);
    const [chosenBreed, setChosenBreed] = useState<any>({});

    const [animalTypesValues, setAnimalTypesValues] = useState<AnimalTypesValues>({
        _id: '',
        hasCategory: false,
        name: ''
    });
    const [categoryValues, setCategoryValues] = useState<any>({});
    const [subCategoryValues, setSubCategoryValues] = useState({});
    const [breedValues, setBreedValues] = useState({});

    const [photo, setPhoto] = useState<any>([]);
    const [selectedPhoto, setSelectedPhoto] = useState<any>([]);

    const [emptyPhoto, setEmptyPhoto] = useState<any>([]);

    const [fatherLineagephoto, setFatherLineagePhoto] = useState<any>('');
    const [motherLineagephoto, setMotherLineagePhoto] = useState<any>('');
    const [clearChildFatherState, setClearChildFatherState] = React.useState(false);
    const [clearChildMotherState, setClearChildMotherState] = React.useState(false);

    const [initialValues, setInitialValues] = useState<any>({
        userId: '',
        animalId: '',
        listingName: '',
        category: 'PETS',
        animalTypeObject: {
            _id: '',
            name: ''
        },
        animalCategoryObject: {
            _id: '',
            name: ''
        },
        animalSubCategoryObject: {
            _id: '',
            name: ''
        },
        breedObject: {
            _id: '',
            name: ''
        },
        gender: '',
        price: null,
        pureBred: '',
        desexed: '',
        // enquiryType: '',
        // enquiryValue: null,
        showOnProfile: true,
        description: '',
        dateOfBirth: null,
        microchipNumber: null,
        picNumber: null,
        identificationNumber: null,
        status: '',
        fromDateLocal: null,
        toDateLocal: null,
        fatherId: null,
        father: {},
        fatherDetail: {},
        motherId: null,
        mother: {},
        motherDetail: {},
        media: [],
        adsDuration: null,
        adsAmount: null,
        suitedFor: '',
        handRaised: '',
        breederId: '',
        chickensBantam: '',
        geneticTraits: '',
        pricing: null,
        enquiryPreference: []
    });

    const { UPDATE_SALES_ANIMAL, GET_USER, GET_LIST_NEW_ANIMAL_TYPE, GET_LIST_CATEGORIES_AND_BREEDS, GET_SALES_ANIMAL } = useGQL();
    const { error: animalTypeErr, loading: AnimalTypeLoading, data: animalTypeList, refetch } = GET_LIST_NEW_ANIMAL_TYPE();
    const { error: catBreedErr, loading: catBreedLoading, data: catBreedData, refetch: catBreedRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();
    const { error: subCatErr, loading: subCatLoading, data: subCatData, refetch: subCatRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();
    const { error: breedErr, loading: breedLoading, data: breedData, refetch: breedRefetch } = GET_LIST_CATEGORIES_AND_BREEDS();
    const { error: pgError, loading: adLoading, data: animalData } = GET_SALES_ANIMAL({ _id: id! });

    const [handleCreate, { loading, data }] = UPDATE_SALES_ANIMAL({ _id: id });
    const [handleUpdate, { loading: loadingUpdate }] = UPDATE_SALES_ANIMAL({ _id: id });

    const [imgConfig, setImgConfig] = useState<typeof defaultImageConfig>(defaultImageConfig);

    const { handleUpload, getImageFromAWS, getVideoFromAWS } = useImageUpload();

    const [selectedOption, setSelectedOption] = useState('');

    const [selectedBreedOption, setSelectedBreedOption] = useState<string[]>([]);

    const [showRadio, setShowRadio] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);

    const [fromDateLocal, setFromDateLocal] = useState<Date | null>(null);

    const [toDateLocal, setToDateLocal] = useState<Date | null>(null);

    const [enquiryTypeModified, setEnquiryTypeModified] = useState(false);

    const [phone, setPhone] = useState('');

    const { openDialog, handleDialogClose, handleDialogOpen } = useDialog();

    const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
    // const [prevShowOnProfile, setPrevShowOnProfile] = useState('No');
    const [user, setUser] = useState<AppUser>();

    const { error: userError, loading: userLoading, data: userData } = GET_USER(`${initialValues.userId}`);

    useEffect(() => {
        const fetchUser = () => {
            const userValue = userData?.getAppUser?.user;
            setUser(userValue);
        };
        fetchUser();
    }, [userData?.getAppUser?.user]);

    const handlePopupOpen = () => {
        setIsProfilePopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsProfilePopupOpen(false);
    };

    const onChangeShowRadio = (event) => {
        setShowRadio(event.target.value);

        if (event.target.value === 'true') {
            setIsProfilePopupOpen(true);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleEnquiryTypeChange = (event) => {
        const { value } = event.target;
        if (value !== ('' || 'ENQUIRE_IN_APP')) {
            setEnquiryTypeModified(true);
        } else {
            setEnquiryTypeModified(false);
        }
    };

    const OpenModal = () => {
        handleDialogOpen();
    };

    const closeDialog = () => {
        handleDialogClose();
        // refetch();
    };
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [videoDialogOpen, setVideoDialogOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState<any>('');

    const openImageDialog = () => {
        setImageDialogOpen(true);
    };

    const closeImageDialog = () => {
        setImageDialogOpen(false);
    };

    const [sireDialogOpen, setSireImageDialogOpen] = useState(false);
    const [damDialogOpen, setDamImageDialogOpen] = useState(false);

    const openLineageDialog = (lineage) => {
        if (lineage === 'sire' || lineage === 'father') setSireImageDialogOpen(true);
        else if (lineage === 'dam' || lineage === 'mother') setDamImageDialogOpen(true);
    };

    const closeLineageDialog = (lineage) => {
        if (lineage === 'sire' || lineage === 'father') setSireImageDialogOpen(false);
        else if (lineage === 'dam' || lineage === 'mother') setDamImageDialogOpen(false);
    };

    const getEnquiryPlaceholder = (enquiryType) => {
        if (enquiryType === 'EMAIL') {
            return 'Email';
        }
        if (enquiryType === 'TEXT' || enquiryType === 'PHONE') {
            return 'Phone';
        }
        return '';
    };

    const playVideo = (url) => {
        setVideoUrl(url);
        setVideoDialogOpen(true);
    };

    const closeVideo = (ind) => {
        setVideoDialogOpen(false);
    };

    const onclickedRemoveImage = (index) => {
        const hasCover = photo.filter((img) => img.isCover === true);
        if (hasCover?.length) {
            const newImages = photo.filter((img, k) => index !== k);
            setPhoto(newImages);
            setSelectedPhoto(newImages);
        } else {
            const newImages = photo.filter((img, k) => index - 1 !== k);
            setPhoto(newImages);
            setSelectedPhoto(newImages);
        }
    };

    const handleOnSubmit = async (values, { setSubmitting }) => {
        if (!photo.length) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Media is required',
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            setSubmitting(false);
            return;
        }
        try {
            const cleanedMedia = values.media.map((mediaObj) => {
                const { url, ...cleanedMediaObj } = mediaObj;
                return cleanedMediaObj;
            });

            const newEnquiry = values.enquiryPreference.map(({ index, ...rest }) => {
                return rest;
            });
            const response: any = await handleCreate({
                variables: {
                    input: {
                        _id: id,
                        userId: values.userId,
                        listingName: values.listingName,
                        category: values.category,
                        animalType: values.animalTypeObject.name,
                        breed: values?.breedObject?.name,
                        animalCategory: values?.animalCategoryObject?.name,
                        animalSubCategory: values?.animalSubCategoryObject?.name,
                        pureBred: values.pureBred,
                        gender: values.gender,
                        price: values.price,
                        desexed: values.desexed,
                        // enquiryType: values.enquiryType,
                        // enquiryValue: values.enquiryValue,
                        showOnProfile: values.showOnProfile,
                        description: values.description,
                        dateOfBirth: values.dateOfBirth,
                        microchipNumber: values.microchipNumber,
                        picNumber: values.picNumber,
                        identificationNumber: values.identificationNumber,
                        fatherId: values.fatherId || null,
                        // fatherDetail: values.fatherDetail,
                        motherId: values.motherId || null,
                        // motherDetail: values.motherDetail,
                        // media: cleanedMedia
                        adsDuration: pricing.month || values.adsDuration,
                        adsAmount: pricing.price || values.adsAmount,
                        suitedFor: values.suitedFor,
                        handRaised: values.handraised,
                        breederId: values.breederId,
                        chickensBantam: values.chickensBantam,
                        geneticTraits: values.geneticTraits,
                        enquiryPreference: newEnquiry,
                        animalTypeId: values.animalTypeObject._id,
                        animalCategoryId: values?.animalCategoryObject?._id,
                        animalSubCategoryId: values?.animalSubCategoryObject?._id,
                        breedId: values?.breedObject?._id
                    }
                }
            });
            if (response?.data?.UpdateOverallSalesAnimal?.message === 'Animal is updated successfully.') {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${response?.data?.UpdateOverallSalesAnimal?.message || 'Animal is updated successfully.'}`,
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Uploading Media...',
                        anchorOrigin: { horizontal: 'center' },
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
                if (response?.data?.UpdateOverallSalesAnimal?.animal) {
                    const animalID = response?.data?.UpdateOverallSalesAnimal?.animal?._id;
                    const media: any = [];
                    // eslint-disable-next-line prettier/prettier
                    const updateParam: any = { _id: animalID, userId: values.userId };
                    await Promise.all(
                        photo.map(async (p, k) => {
                            if (typeof p.file === 'string' && p.file.length > 0) {
                                media.push({ file: p.file, caption: p.caption, isCover: p.isCover });
                            } else if (p.file?.name) {
                                const uuid = uuidv4();
                                // Split the file name by the dot character to get an array of parts
                                const fileParts = p.file.name.split('.');
                                // Get the last part of the array, which is typically the file extension
                                const fileExtension = fileParts[fileParts.length - 1];

                                if (fileExtension === 'mp4' || fileExtension === 'mov') {
                                    await handleUpload(p.file, `${values.userId}/videos/generalVideo/video_${uuid}.${fileExtension}`);
                                    await media.push({
                                        file: `${values.userId}/videos/generalVideo/video_${uuid}.${fileExtension}`,
                                        caption: p.caption,
                                        isCover: p.isCover
                                    });
                                } else {
                                    await handleUpload(p.file, `${values.userId}/images/generalImage/img_${p.file?.name}`);

                                    media.push({
                                        file: `${values.userId}/images/generalImage/img_${p.file?.name}`,
                                        caption: p.caption,
                                        isCover: p.isCover
                                    });
                                }
                            }
                        })
                    );
                    if (media.length > 0) {
                        updateParam.media = media;
                    }

                    if (values?.fatherId && values?.fatherId !== '') {
                        updateParam.fatherId = values.fatherId;
                    }
                    if (values?.motherId && values?.motherId !== '') {
                        updateParam.motherId = values.motherId;
                    }

                    if (values?.fatherDetail && values?.fatherDetail?.image) {
                        const uuid = uuidv4();
                        // Split the file name by the dot character to get an array of parts
                        const fileParts = values?.fatherDetail?.image?.name.split('.');
                        // Get the last part of the array, which is typically the file extension
                        const fileExtension = fileParts[fileParts.length - 1];
                        await handleUpload(
                            values?.fatherDetail?.image,
                            `${values.userId}/images/generalImage/img_${uuid}.${fileExtension}`
                        );
                        updateParam.fatherDetail = {
                            image: `${values.userId}/images/generalImage/img_${uuid}.${fileExtension}`,
                            listingName: values?.fatherDetail?.listingName
                        };
                    }

                    if (values?.fatherDetail && values?.fatherDetail?.hasOwnProperty('media')) {
                        updateParam.fatherDetail = {
                            image: values?.fatherDetail?.media[0]?.file,
                            listingName: values?.fatherDetail?.listingName
                        };
                    }

                    if (values?.motherDetail && values?.motherDetail?.image) {
                        const uuid = uuidv4();
                        // Split the file name by the dot character to get an array of parts
                        const fileParts = values?.motherDetail?.image?.name.split('.');
                        // Get the last part of the array, which is typically the file extension
                        const fileExtension = fileParts[fileParts?.length - 1];
                        await handleUpload(
                            values?.motherDetail?.image,
                            `${values.userId}/images/generalImage/img_${uuid}.${fileExtension}`
                        );
                        updateParam.motherDetail = {
                            image: `${values.userId}/images/generalImage/img_${uuid}.${fileExtension}`,
                            listingName: values?.motherDetail?.listingName
                        };
                    }

                    if (values?.motherDetail && values?.motherDetail?.hasOwnProperty('media')) {
                        updateParam.motherDetail = {
                            image: values?.motherDetail?.media[0]?.file,
                            listingName: values?.motherDetail?.listingName
                        };
                    }

                    await handleUpdate({
                        variables: {
                            input: updateParam
                        },
                        onCompleted: (updateImages) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Media are uploaded succesfully.',
                                    anchorOrigin: { horizontal: 'center' },
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    }
                                })
                            );
                            setTimeout(() => {
                                navigate(`/for-sale/about/${id}`);
                            }, 2000);
                        },
                        onError: (updateImageError: any) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: updateImageError?.message || 'Media cannot be uploaded. Try again...',
                                    anchorOrigin: { horizontal: 'center' },
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    }
                                })
                            );
                            setSubmitting(false);
                        }
                    });
                }
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err?.message || 'Animal Addition failed. Try again...',
                    anchorOrigin: { horizontal: 'center' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
            setSubmitting(false);
        }
    };

    useEffect(() => {
        setPricingList([]);
        setSuitedForList([]);
        setChickensBantamList([]);
        setgeneticTraitsList([]);
        if (chosenAnimalType) {
            setPricingList(chosenAnimalType.pricing);
            setExtraFields(chosenAnimalType.extraFields);
        }
    }, [chosenAnimalType]);

    useEffect(() => {
        setBreedList([]);
        if (breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds) {
            setBreedList(breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds[0]?.breeds);
        }
        if (!chosenCategory?.hasCategory) {
            const matchingCategory = breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds.find(
                (category) => category.name === chosenCategory.name
            );

            // Check if a matching category was found
            if (matchingCategory) {
                setBreedList(matchingCategory.breeds);
            }
        }
    }, [breedData, chosenCategory]);

    useEffect(() => {
        if (chosenAnimalType && chosenAnimalType.hasCategory) {
            catBreedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: chosenAnimalType.hasCategory
                }
            });
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false
                }
            });
        }
    }, [chosenAnimalType]);

    useEffect(() => {
        if (chosenCategory && chosenCategory.hasCategory) {
            subCatRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: chosenCategory.hasCategory,
                    categoryId: chosenCategory._id,
                    categoryName: chosenCategory.name
                }
            });
            // setChosenSubCategory(subCatData);
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false,
                    categoryId: chosenCategory?._id,
                    categoryName: chosenCategory?.name
                }
            });
        }
    }, [chosenCategory]);

    useEffect(() => {
        if (chosenSubCategory && chosenSubCategory.hasCategory) {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: true,
                    categoryId: chosenCategory._id,
                    categoryName: chosenSubCategory.name
                }
            });
        } else {
            breedRefetch({
                input: {
                    animalTypeId: chosenAnimalType._id,
                    hasCategory: false,
                    categoryId: chosenCategory?._id,
                    categoryName: chosenSubCategory?.name
                }
            });
        }
    }, [chosenSubCategory]);

    useEffect(() => {
        const fetchAnimal = async () => {
            if (animalData?.ViewSalesDetailPage?.animal) {
                const animalValues = animalData?.ViewSalesDetailPage?.animal;

                // fetching images from AWS
                let media: any = [];

                // Check if animalValues has a media property
                if (animalValues.media) {
                    // Fetch images from AWS and update media
                    media = await Promise.all(
                        animalValues.media.map(async (med, i) => {
                            med = JSON.parse(JSON.stringify(med));
                            const furl = await getImageFromAWS(med.file);
                            const vurl = await getVideoFromAWS(med.file);
                            med.url = furl;
                            med.vurl = vurl;
                            return med;
                        })
                    );
                }

                const selectedAnimalType = animalTypeList?.ListNewAnimalType?.newAnimalTypes.find(
                    (type) => type._id === animalValues.animalTypeId
                );

                // setInitialValues({
                //     ...animalValues,
                //     animalType: animalValues.animalType,
                //     animalCategory: animalValues.animalCategory,
                //     animalSubCategory: animalValues.animalSubCategory,
                //     microchipNumber: animalValues.microchipNumber,
                //     identificationNumber: animalValues.identificationNumber,
                //     media, // Add the fetched images to media
                //     pricing: animalValues.adsDuration
                // });
                if (selectedAnimalType) {
                    setChosenAnimalType(selectedAnimalType);
                }

                if (
                    animalData?.ViewSalesDetailPage?.father ||
                    animalData?.ViewSalesDetailPage?.mother ||
                    animalData?.ViewSalesDetailPage?.fatherDetail ||
                    animalData?.ViewSalesDetailPage?.motherDetail
                ) {
                    const animalFatherLineageValues = animalData?.ViewSalesDetailPage?.father;
                    const animalMotherLineageValues = animalData?.ViewSalesDetailPage?.mother;
                    const animalFatherDetailLineageValues = animalData?.ViewSalesDetailPage?.fatherDetail;
                    const animalMotherDetailLineageValues = animalData?.ViewSalesDetailPage?.motherDetail;
                    // const parentLineageValues = animalData?.ViewSalesDetailPage;

                    if (animalFatherLineageValues) {
                        let coverFatherMedia = animalFatherLineageValues.media;
                        if (coverFatherMedia?.media?.length > 0) {
                            coverFatherMedia = coverFatherMedia.media[0];
                            const coverFatherMediaCover = coverFatherMedia?.media?.find((med: any) => med.isCover === true);
                            coverFatherMedia = coverFatherMediaCover || coverFatherMedia;
                        }
                        if (coverFatherMedia) {
                            const furl = await getImageFromAWS(coverFatherMedia[0].file);
                            setFatherLineagePhoto(furl);
                        }
                    }
                    if (animalMotherLineageValues) {
                        let coverMotherMedia = animalMotherLineageValues.media;
                        if (coverMotherMedia?.media?.length > 0) {
                            coverMotherMedia = coverMotherMedia.media[0];
                            const coverMotherMediaCover = coverMotherMedia?.media?.find((med: any) => med.isCover === true);
                            coverMotherMedia = coverMotherMediaCover || coverMotherMedia;
                        }
                        if (coverMotherMedia) {
                            const furl = await getImageFromAWS(coverMotherMedia[0].file);
                            setMotherLineagePhoto(furl);
                        }
                    }
                    if (animalFatherDetailLineageValues) {
                        let coverFatherMedia = animalFatherDetailLineageValues.media;
                        if (coverFatherMedia?.media?.length > 0) {
                            coverFatherMedia = coverFatherMedia.media[0];
                            const coverFatherMediaCover = coverFatherMedia?.media?.find((med: any) => med.isCover === true);
                            coverFatherMedia = coverFatherMediaCover || coverFatherMedia;
                        }
                        if (coverFatherMedia) {
                            const furl = await getImageFromAWS(coverFatherMedia[0].file);
                            setFatherLineagePhoto(furl);
                        }
                    }
                    if (animalMotherDetailLineageValues) {
                        let coverMotherMedia = animalMotherDetailLineageValues.media;
                        if (coverMotherMedia?.media?.length > 0) {
                            coverMotherMedia = coverMotherMedia.media[0];
                            const coverMotherMediaCover = coverMotherMedia?.media?.find((med: any) => med.isCover === true);
                            coverMotherMedia = coverMotherMediaCover || coverMotherMedia;
                        }
                        if (coverMotherMedia) {
                            const furl = await getImageFromAWS(coverMotherMedia[0].file);
                            setMotherLineagePhoto(furl);
                        }
                    }

                    const value = { ...animalValues, media };
                    const enquiry = animalValues?.enquiryPreference?.map((item, indx) => ({ index: indx, ...item })) ?? [];

                    const newValues = {
                        ...value,
                        animalTypeObject: {
                            name: animalValues?.animalType,
                            _id: animalValues?.animalTypeId
                        },
                        animalCategoryObject: {
                            name: animalValues?.animalCategory,
                            _id: animalValues?.animalCategoryId
                        },

                        animalSubCategoryObject: {
                            name: animalValues?.animalSubCategory,
                            _id: animalValues?.animalSubCategoryId
                        },
                        breedObject: {
                            name: animalValues?.breed,
                            _id: animalValues?.breedId
                        },
                        microchipNumber: animalValues?.microchipNumber,
                        identificationNumber: animalValues?.identificationNumber,
                        media, // Add the fetched images to media
                        father: animalFatherLineageValues,
                        mother: animalMotherLineageValues,
                        fatherDetail: animalFatherDetailLineageValues || null,
                        motherDetail: animalMotherDetailLineageValues || null,
                        pricing: animalValues?.adsDuration,
                        enquiryPreference: enquiry
                    };

                    setInitialValues(newValues);
                    setPhoto(media);
                    setSelectedPhoto(media);
                    if (selectedAnimalType) {
                        setChosenAnimalType(selectedAnimalType);
                    }
                } else {
                    const value = { ...animalValues, media };
                    const enquiry = animalValues?.enquiryPreference?.map((item, indx) => ({ index: indx, ...item })) ?? [];

                    const newValues = {
                        ...value,
                        animalTypeObject: {
                            name: animalValues?.animalType,
                            _id: animalValues?.animalTypeId
                        },
                        animalCategoryObject: {
                            name: animalValues?.animalCategory,
                            _id: animalValues?.animalCategoryId
                        },

                        animalSubCategoryObject: {
                            name: animalValues?.animalSubCategory,
                            _id: animalValues?.animalSubCategoryId
                        },
                        breedObject: {
                            name: animalValues?.breed,
                            _id: animalValues?.breedId
                        },
                        microchipNumber: animalValues?.microchipNumber,
                        identificationNumber: animalValues?.identificationNumber,
                        media, // Add the fetched images to media
                        pricing: animalValues?.adsDuration,
                        enquiryPreference: enquiry
                    };
                    setInitialValues(newValues);

                    setPhoto(media);
                    setSelectedPhoto(media);
                    if (selectedAnimalType) {
                        setChosenAnimalType(selectedAnimalType);
                    }
                }
                // if (
                //     animalData?.ViewSalesDetailPage?.animal?.offspringFormDetail?.father ||
                //     animalData?.ViewSalesDetailPage?.animal?.offspringFormDetail?.mother
                // ) {
                //     const animalFatherLineageValues = animalData?.ViewSalesDetailPage?.animal?.offspringFormDetail?.father;
                //     const animalMotherLineageValues = animalData?.ViewSalesDetailPage?.animal?.offspringFormDetail?.mother;

                //     const coverFatherMedia = animalFatherLineageValues?.image;
                //     if (coverFatherMedia) {
                //         const furl = await getImageFromAWS(coverFatherMedia);
                //         setFatherLineagePhoto(furl);
                //     }

                //     const coverMotherMedia = animalMotherLineageValues?.image;
                //     if (coverMotherMedia) {
                //         const furl = await getImageFromAWS(coverMotherMedia);
                //         setMotherLineagePhoto(furl);
                //     }

                //     const value = { ...initialValues, media };
                //     setInitialValues({
                //         ...animalValues,
                //         animalType: animalValues.animalType,
                //         animalCategory: animalValues.animalCategory,
                //         animalSubCategory: animalValues.animalSubCategory,
                //         microchipNumber: animalValues.microchipNumber,
                //         identificationNumber: animalValues.identificationNumber,
                //         media, // Add the fetched images to media
                //         father: animalFatherLineageValues,
                //         mother: animalMotherLineageValues,
                //         pricing: animalValues.adsDuration
                //     });
                //     setPhoto(media);
                //     if (selectedAnimalType) {
                //         setChosenAnimalType(selectedAnimalType);
                //     }
                // } else {
                //     const value = { ...initialValues, media };
                //     setInitialValues({
                //         ...animalValues,
                //         animalType: animalValues.animalType,
                //         animalCategory: animalValues.animalCategory,
                //         animalSubCategory: animalValues.animalSubCategory,
                //         microchipNumber: animalValues.microchipNumber,
                //         identificationNumber: animalValues.identificationNumber,
                //         media, // Add the fetched images to media
                //         pricing: animalValues.adsDuration
                //     });
                //     setPhoto(media);
                //     if (selectedAnimalType) {
                //         setChosenAnimalType(selectedAnimalType);
                //     }
                // }
            }

            // for (let i = photo.length - 1; i < 7; i + 1) {
            //     setEmptyPhoto([...emptyPhoto, '<img src={placeHolder} key={i} width="64" height="64" alt="YCP description" />']);
            // }
        };
        fetchAnimal().catch(console.error);
    }, [adLoading, animalData]);

    useEffect(() => {
        setSelectedPhoto(photo);
    }, [photo]);

    useEffect(() => {
        const fetchAnimalType = async () => {
            if (animalData?.ViewSalesDetailPage?.animal.animalTypeId) {
                const animalTypeValues = animalData?.ViewSalesDetailPage?.animal.animalTypeId;

                const animalTypeObject = await animalTypeList?.ListNewAnimalType?.newAnimalTypes.filter(
                    (animal) => animal._id === animalTypeValues
                );
                await setAnimalTypesValues(animalTypeObject[0] || {});
            }
        };
        fetchAnimalType();
        const param = {
            animalTypeId: animalTypesValues._id,
            hasCategory: false
        };

        // if (chosenSubCategory) {
        //     param.categoryId = chosenCategory.id;
        //     param.categoryName = chosenSubCategory.name;
        // }
        breedRefetch({
            input: param
        });
    }, [animalTypeList, animalData]);

    useEffect(() => {
        const fetchBreedType = async () => {
            if (animalData?.ViewSalesDetailPage?.animal.breedId) {
                const breedTypeValues = animalData?.ViewSalesDetailPage?.animal.breedId;
                const breedTypeObject = await breedData?.ListCategoriesAndBreeds?.categoriesAndBreeds?.breeds?.find(
                    (breed) => breed._id === breedTypeValues
                );
                // await setBreedValues(breedTypeObject[0] || {});
                if (breedTypeObject !== undefined) {
                    await setBreedValues(breedTypeObject);
                } else {
                    await setBreedValues({});
                }
            }
        };
        fetchBreedType();
    }, [breedData, animalData]);

    useEffect(() => {
        const fetchCategory = async () => {
            if (animalData?.ViewSalesDetailPage?.animal.animalCategoryId) {
                const categoryTypeValues = animalData?.ViewSalesDetailPage?.animal.animalCategoryId;
                const matchingCategory = catBreedData?.ListCategoriesAndBreeds?.categoriesAndBreeds.find(
                    (category) => category._id === categoryTypeValues
                );

                if (matchingCategory) {
                    setCategoryValues(matchingCategory);
                }
                setChosenCategory(matchingCategory);
            }
        };
        fetchCategory();
    }, [animalData, catBreedData]);

    useEffect(() => {
        const fetchSubCategory = async () => {
            if (animalData?.ViewSalesDetailPage?.animal.animalSubCategoryId) {
                const categoryTypeValues = animalData?.ViewSalesDetailPage?.animal.animalSubCategoryId;
                const matchingSubCategory = subCatData?.ListCategoriesAndBreeds?.categoriesAndBreeds.find(
                    (category) => category._id === categoryTypeValues
                );

                if (matchingSubCategory) {
                    setSubCategoryValues(matchingSubCategory);
                    setChosenSubCategory(matchingSubCategory);
                }
            }
        };
        fetchSubCategory();
    }, [animalData, subCatData]);

    // useEffect(() => {
    //     // Check if the value changed from 'No' to 'Yes'
    //     if (newShow === 'Yes' && showRadio === 'No') {
    //         setIsProfilePopupOpen(true);
    //     }
    // }, [showRadio]);

    const toCamelCase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const breadcrumb = (
        <MuiBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="medium" />}>
            <Link underline="none" color="text.primary" href="/for-sale/list">
                Sale Animals
            </Link>
            <Typography color="text.primary">Edit listing</Typography>
        </MuiBreadcrumbs>
    );

    const items = Array.from({ length: 6 - photo.filter((media: any) => !media.isCover)?.length }).map((_, index) => (
        <ImageListItem
            key={index}
            sx={{
                maxWidth: 150,
                display: 'inline-block',
                position: 'relative',
                padding: '0.5rem'
            }}
        >
            <Box>{/* <img src={placeHolder} width="64" height="64" alt="YCP description" /> */}</Box>
        </ImageListItem>
    ));

    return (
        <>
            <Paper sx={{ px: 2, py: 1, mt: 0.5, mb: 2 }}>{breadcrumb}</Paper>
            <MainCard title="Edit Animal">
                {open && <Snackbar />}
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                        listingName: Yup.string().min(3, 'Listing Name must be at least 3 characters').required('Listing Name is required'),
                        // category: Yup.string(),
                        animalTypeObject: Yup.mixed()
                            .required('Animal Type is required')
                            .test('valid-selection', 'Animal Type is required', (value) => value && value._id && value.name),

                        breedObject: Yup.mixed()
                            .required('Breed is required')
                            .test('valid-selection', 'Breed is required', (value) => value && value._id && value.name),
                        pricing: Yup.mixed().required('Select a value'),

                        animalCategoryObject: Yup.object().nullable(),
                        animalSubCategoryObject: Yup.object().nullable(),
                        gender: Yup.string().required('Sex is required'),
                        price: Yup.mixed().required('Price is required'),
                        pureBred: Yup.string().required('Select a value'),
                        desexed: Yup.string().nullable(),
                        microchipNumber: Yup.string().when('animalType', {
                            is: (animalTypes) => animalTypes === 'Cats' || animalTypes === 'Dogs',
                            then: Yup.string()
                                .required('Microchip Number is required')
                                .matches(/[0-9]{15}/, 'Must be 15  characters long.'),
                            otherwise: Yup.string().nullable()
                        }),
                        picNumber: initialValues.picNumber
                            ? Yup.string()
                                  .required('Pic Number is Required.')
                                  .matches(
                                      /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{8}$/,
                                      'Pic number should be 8 digit long and alphanumeric value.'
                                  )
                            : Yup.string().nullable(),
                        // enquiryType: Yup.mixed().required('Select a value'),
                        // enquiryValue: Yup.string()
                        //     .nullable()
                        //     .test('len', 'Must be 7 characters long', (val) => {
                        //         if (val && val.length >= 7) return true;

                        //         if (!val) return true;

                        //         return false;
                        //     })
                        //     .max(500),
                        showOnProfile: Yup.boolean(),
                        description: Yup.string().max(500).required('Description is required'),
                        dateOfBirth: Yup.date().optional().nullable(),
                        // microchipNumber: Yup.string(),
                        // identificationNumber: Yup.string()
                        // profileImage: Yup.string()
                        enquiryPreference: Yup.array()
                            .of(
                                Yup.object({
                                    enquiryType: Yup.string().required('Enquiry type is required.'),
                                    enquiryValue: Yup.string().when('enquiryType', {
                                        is: 'EMAIL',
                                        then: Yup.string().email('Invalid email format.').required('Email is required.'),
                                        otherwise: Yup.string().when('enquiryType', {
                                            is: 'PHONE',
                                            then: Yup.string().min(10, 'Invalid phone number.').required('Phone number is required.'),
                                            otherwise: Yup.string().when('enquiryType', {
                                                is: 'TEXT',
                                                then: Yup.string().min(10, 'Invalid phone number.').required('Phone number is required.'),
                                                otherwise: Yup.string().nullable()
                                            })
                                        })
                                    }),
                                    index: Yup.number()
                                })
                            )
                            .test('isNotEmpty', 'Required Enquiry Preference.', (array: any) => {
                                return array && array?.length > 0;
                            })
                    })}
                    onSubmit={handleOnSubmit}
                >
                    {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack direction="column" spacing={3} sx={{ mt: 0.75 }}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                        <Grid item xs={6} sm={6} md={6} lg={5}>
                                            <Stack direction="column" gap={2} spacing={1}>
                                                {/* <FormControl>
                                                <Typography variant="h3" component="div" marginTop="1rem">
                                                    Listing and animal details
                                                </Typography>
                                            </FormControl> */}
                                                <FormControl>
                                                    <>
                                                        <Grid container className="media-lists" sx={{ height: '100%' }}>
                                                            <Grid item xs={4} sx={{ padding: '0 0.5rem' }}>
                                                                {photo?.length > 0 ? (
                                                                    photo
                                                                        .filter((media: any) => media.isCover)
                                                                        .map((media: any, ind) => {
                                                                            const imageURL = media.url || media.imageURL;
                                                                            if (media?.file && typeof media?.file === 'string') {
                                                                                const distinct_key = media?.file?.split('/');
                                                                                if (distinct_key[1] === 'videos') {
                                                                                    const new_key = `${distinct_key
                                                                                        .slice(0, -1)
                                                                                        .join('/')}/video-thumb/${distinct_key.slice(-1)}`;
                                                                                    const new_url = new_key.replace('.png', '.mp4');
                                                                                    media.isVideo = true;
                                                                                    media.videoUrl = new_url;
                                                                                }
                                                                            }
                                                                            return (
                                                                                <Card
                                                                                    key={ind}
                                                                                    sx={{
                                                                                        // maxWidth: 120,
                                                                                        // display: 'inline-block',
                                                                                        position: 'relative',
                                                                                        width: '100%'
                                                                                    }}
                                                                                    className="media-first"
                                                                                >
                                                                                    <Box
                                                                                        component="span"
                                                                                        className="image-upload-dialog-remove-icon"
                                                                                        onClick={() => onclickedRemoveImage(ind)}
                                                                                    >
                                                                                        <IconDismissSquare />
                                                                                    </Box>

                                                                                    {/* {imageURL ? (
                                                                                    <img
                                                                                        crossOrigin="anonymous"
                                                                                        height="194"
                                                                                        src={`${imageURL}`}
                                                                                        srcSet={`${imageURL}`}
                                                                                        alt={media.caption}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={placeHolder}
                                                                                        width="64"
                                                                                        height="64"
                                                                                        alt="YCP description"
                                                                                    />
                                                                                )} */}
                                                                                    {media.isVideo ? (
                                                                                        <Box className="video-wrap">
                                                                                            <IconButton
                                                                                                aria-label="Play"
                                                                                                color="secondary"
                                                                                                onClick={() =>
                                                                                                    playVideo(
                                                                                                        media.vurl ||
                                                                                                            URL.createObjectURL(media.file)
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <PlayArrowRoundedIcon
                                                                                                    sx={{
                                                                                                        color: '#cb3e19',
                                                                                                        position: 'absolute',
                                                                                                        zIndex: 1
                                                                                                    }}
                                                                                                />
                                                                                            </IconButton>
                                                                                            <img
                                                                                                height="194"
                                                                                                src={`${imageURL}`}
                                                                                                srcSet={`${imageURL}`}
                                                                                                alt={media.caption}
                                                                                            />
                                                                                        </Box>
                                                                                    ) : (
                                                                                        !media.isVideo && (
                                                                                            <img
                                                                                                crossOrigin="anonymous"
                                                                                                height="194"
                                                                                                src={`${imageURL}`}
                                                                                                srcSet={`${imageURL}`}
                                                                                                alt={media.caption}
                                                                                            />
                                                                                        )
                                                                                    )}
                                                                                </Card>
                                                                            );
                                                                        })
                                                                ) : (
                                                                    <Card
                                                                        sx={{
                                                                            // maxWidth: 120,
                                                                            // display: 'inline-block',
                                                                            position: 'relative',
                                                                            width: '100%'
                                                                        }}
                                                                        className="media-first"
                                                                    >
                                                                        {/* <img src={placeHolder} width="64" height="64" alt="YCP description" /> */}
                                                                    </Card>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={8} className="media-sub-images">
                                                                <ImageList sx={{ width: 500, height: '100%' }} cols={3} rowHeight={64}>
                                                                    {photo
                                                                        .filter((media: any) => !media.isCover)
                                                                        .map((media: any, ind) => {
                                                                            const imageURL = media.url || media.imageURL;
                                                                            if (media?.file && typeof media?.file === 'string') {
                                                                                const distinct_key = media?.file?.split('/');
                                                                                if (distinct_key[1] === 'videos') {
                                                                                    const new_key = `${distinct_key
                                                                                        .slice(0, -1)
                                                                                        .join('/')}/video-thumb/${distinct_key.slice(-1)}`;
                                                                                    const new_url = new_key.replace('.png', '.mp4');
                                                                                    media.isVideo = true;
                                                                                    media.videoUrl = new_url;
                                                                                }
                                                                            }
                                                                            return (
                                                                                <ImageListItem
                                                                                    key={ind}
                                                                                    sx={{
                                                                                        maxWidth: 150,
                                                                                        display: 'inline-block',
                                                                                        position: 'relative',
                                                                                        padding: '0.5rem'
                                                                                    }}
                                                                                >
                                                                                    <Box>
                                                                                        <Box
                                                                                            component="span"
                                                                                            className="image-upload-dialog-remove-icon"
                                                                                            onClick={() => onclickedRemoveImage(ind + 1)}
                                                                                        >
                                                                                            <IconDismissSquare />
                                                                                        </Box>

                                                                                        {/* <img
                                                                                        crossOrigin="anonymous"
                                                                                        height="194"
                                                                                        src={`${imageURL}` || placeHolder}
                                                                                        srcSet={`${imageURL}`}
                                                                                        alt={media.caption}
                                                                                    /> */}
                                                                                        {media.isVideo ? (
                                                                                            <Box className="video-wrap">
                                                                                                <IconButton
                                                                                                    aria-label="Play"
                                                                                                    color="secondary"
                                                                                                    onClick={() =>
                                                                                                        playVideo(
                                                                                                            media.vurl ||
                                                                                                                URL.createObjectURL(
                                                                                                                    media.file
                                                                                                                )
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <PlayArrowRoundedIcon
                                                                                                        sx={{
                                                                                                            color: '#cb3e19',
                                                                                                            position: 'absolute',
                                                                                                            zIndex: 1
                                                                                                        }}
                                                                                                    />
                                                                                                </IconButton>
                                                                                                <img
                                                                                                    height="194"
                                                                                                    src={`${imageURL}`}
                                                                                                    srcSet={`${imageURL}`}
                                                                                                    alt={media.caption}
                                                                                                />
                                                                                            </Box>
                                                                                        ) : (
                                                                                            !media.isVideo && (
                                                                                                <img
                                                                                                    crossOrigin="anonymous"
                                                                                                    height="194"
                                                                                                    src={`${imageURL}`}
                                                                                                    srcSet={`${imageURL}`}
                                                                                                    alt={media.caption}
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </Box>
                                                                                </ImageListItem>
                                                                            );
                                                                        })}

                                                                    {items}
                                                                </ImageList>
                                                            </Grid>
                                                        </Grid>

                                                        {photo?.length > 0 && (
                                                            <Button
                                                                variant="outlined"
                                                                size="large"
                                                                color="secondary"
                                                                style={{
                                                                    marginTop: '20px',
                                                                    marginBottom: '10px',
                                                                    textTransform: 'inherit'
                                                                }}
                                                                onClick={openImageDialog}
                                                            >
                                                                <span style={{ color: '#45110B' }}>Add media</span>
                                                            </Button>
                                                        )}
                                                    </>
                                                    <ImageUploadDialog
                                                        openDialog={imageDialogOpen}
                                                        photos={photo?.length ? photo : selectedPhoto}
                                                        settingPhoto={setSelectedPhoto}
                                                        handleDialogClose={closeImageDialog}
                                                        multiple
                                                        showisCoverField
                                                        savePhoto={() => {
                                                            setPhoto(selectedPhoto);
                                                        }}
                                                    />
                                                    {photo?.length === 0 && (
                                                        <Button
                                                            variant="outlined"
                                                            component={FormLabel}
                                                            size="large"
                                                            color="secondary"
                                                            style={{ marginTop: '20px', marginBottom: '10px', textTransform: 'inherit' }}
                                                            disabled={photo?.length == 7}
                                                        >
                                                            <input
                                                                type="file"
                                                                name="image"
                                                                accept="image/png, image/gif, image/jpeg, video/mp4, video/mov"
                                                                id="image"
                                                                onChange={async (event) => {
                                                                    invariant(event.target.files, 'file not empty');
                                                                    const files = Array.from(new Set(event.target.files));
                                                                    const newFiles: Array<any> = [];
                                                                    for (const file of files) {
                                                                        const isImage = file.type.startsWith('image/');
                                                                        const isVideo = file.type.startsWith('video/');
                                                                        const url = URL.createObjectURL(file);
                                                                        const imageURL = isVideo ? await getVideoCover(url, 4) : url;

                                                                        if (isVideo) {
                                                                            const video = document.createElement('video');
                                                                            video.preload = 'metadata';
                                                                            video.src = url;
                                                                            const videoDuration: any = await new Promise((resolve) => {
                                                                                video.onloadedmetadata = () => resolve(video.duration);
                                                                            });

                                                                            if (videoDuration <= 120) {
                                                                                newFiles.push({
                                                                                    file,
                                                                                    caption: '',
                                                                                    isCover: false,
                                                                                    isVideo,
                                                                                    imageURL
                                                                                });
                                                                            } else {
                                                                                alert('Video duration should be less than 2 minutes.');
                                                                            }
                                                                        } else {
                                                                            const uploadFile = file;
                                                                            const newFile = {
                                                                                file: uploadFile,
                                                                                caption: '',
                                                                                isCover: false,
                                                                                isVideo,
                                                                                imageURL
                                                                            };
                                                                            newFiles.push(newFile);
                                                                        }
                                                                    }
                                                                    setPhoto([...photo, ...newFiles]);
                                                                    await setImageDialogOpen(true);
                                                                }}
                                                                hidden
                                                            />
                                                            <span style={{ color: '#45110B' }}>Add media</span>
                                                        </Button>
                                                    )}
                                                </FormControl>

                                                {/* <FormControl>
                                                <InputLabel htmlFor="userId">User</InputLabel>
                                                <SelectUserList
                                                    name="userId"
                                                    placeholder="Select User"
                                                    defValue={values.name}
                                                    onChangeFn={setFieldValue}
                                                    classes={errors.userId && touched.userId ? 'error' : ''}
                                                />
                                                {touched.userId && errors.userId && (
                                                    <FormHelperText error id="name-error">
                                                        {errors.userId}
                                                    </FormHelperText>
                                                )}
                                            </FormControl> */}

                                                <FormControl>
                                                    <Typography variant="h3" component="div" marginTop="1rem" className="color-red h4">
                                                        Animal details
                                                    </Typography>
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel htmlFor="listingName">Animal listing name</InputLabel>
                                                    <TextField
                                                        placeholder="Name of listing"
                                                        value={values.listingName}
                                                        name="listingName"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        size="small"
                                                    />
                                                    {touched.listingName && errors.listingName && (
                                                        <FormHelperText error id="name-error">
                                                            {errors.listingName}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                                <FormControl>
                                                    <InputLabel htmlFor="gender">Sex</InputLabel>
                                                    <Select
                                                        id="gender"
                                                        name="gender"
                                                        className={values.gender ? '' : 'GenderSelectPlaceholder'}
                                                        placeholder="Select"
                                                        onChange={handleChange}
                                                        value={values.gender}
                                                        size="small"
                                                        defaultValue=""
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            // if (selected.length === 0) {
                                                            //     return <em>Select Gender</em>;
                                                            // }

                                                            const selectedGender = genderOptions.find(
                                                                (option) => option.value === selected
                                                            );
                                                            if (selectedGender) {
                                                                // Capitalize the first letter of the label
                                                                const capitalizedLabel =
                                                                    selectedGender.label.charAt(0).toUpperCase() +
                                                                    selectedGender.label.slice(1).toLowerCase();
                                                                return capitalizedLabel;
                                                            }
                                                            return '';
                                                        }}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {/* <MenuItem disabled value="">
                                                        <em>Select Gender</em>
                                                    </MenuItem> */}
                                                        {genderOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.gender && touched.gender && (
                                                        <FormHelperText error id="gender-error">
                                                            {errors.gender}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel htmlFor="price">Price</InputLabel>
                                                    <TextField
                                                        size="small"
                                                        placeholder="Price"
                                                        value={values.price}
                                                        name="price"
                                                        type="text"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            const numericValue = parseFloat(newValue) || null;

                                                            setFieldValue('price', numericValue);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    {touched.price && errors.price && (
                                                        <FormHelperText error id="price-error">
                                                            {errors.price}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel htmlFor="description">Description</InputLabel>
                                                    <TextField
                                                        placeholder="Description"
                                                        value={values.description}
                                                        multiline
                                                        inputProps={{
                                                            maxLength: CHARACTER_LIMIT
                                                        }}
                                                        helperText={`${values.description?.length}/${CHARACTER_LIMIT}`}
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        size="small"
                                                    />
                                                    {touched.description && errors.description && (
                                                        <FormHelperText error id="name-error">
                                                            {errors.description}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <FormControl>
                                                    <Typography variant="h3" component="div" marginTop="1rem" className="color-red h4">
                                                        Category
                                                    </Typography>
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel htmlFor="animalType">Animal type</InputLabel>

                                                    {!AnimalTypeLoading && (
                                                        <Autocomplete
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    paddingTop: '0px !important',
                                                                    paddingBottom: '0px !important'
                                                                }
                                                            }}
                                                            id="animalTypeObject"
                                                            className={errors.animalTypeObject && touched.animalTypeObject ? 'error' : ''}
                                                            placeholder="Select"
                                                            size="small"
                                                            disabled
                                                            value={chosenAnimalType}
                                                            open={animalType}
                                                            onOpen={() => {
                                                                setAnimalType(true);
                                                            }}
                                                            onClose={() => {
                                                                refetch();
                                                                setAnimalType(false);
                                                                setAnimalCategory(false);
                                                                setAnimalSubCategory(false);
                                                                setAnimalBreed(false);
                                                            }}
                                                            isOptionEqualToValue={(option: any, value) => option._id === value._id}
                                                            getOptionLabel={(option: any) => `${option.name || ''}`}
                                                            options={animalTypeList?.ListNewAnimalType?.newAnimalTypes}
                                                            loading={AnimalTypeLoading}
                                                            // onChange={(event: any, newValue: any | null, reason) => {
                                                            //     console.log('inputchange', newValue, reason);
                                                            //     if (newValue) {
                                                            //         setFieldValue('animalType', newValue?.name);
                                                            //         setFieldValue('animalCategory', '');
                                                            //         setFieldValue('animalSubCategory', '');
                                                            //         setFieldValue('breed', '');
                                                            //         setChosenAnimalType(newValue);
                                                            //         setChosenCategory({});
                                                            //         setChosenSubCategory({});
                                                            //         setChosenBreed({});
                                                            //     } else {
                                                            //         setChosenAnimalType('');
                                                            //         setFieldValue('animalType', '');
                                                            //         setFieldValue('animalCategory', '');
                                                            //         setFieldValue('animalSubCategory', '');
                                                            //         setFieldValue('breed', '');
                                                            //         setChosenCategory({});
                                                            //         setChosenSubCategory({});

                                                            //         setChosenBreed({});
                                                            //         setBreedList([]);
                                                            //         setFieldValue('breed', '');
                                                            //     }

                                                            //     if (reason === 'clear') {
                                                            //         setBreedList([]);
                                                            //         setFieldValue('animalCategory', '');
                                                            //         setFieldValue('breed', '');
                                                            //     }
                                                            // }}
                                                            // onInputChange={(event, newInputValue) => {
                                                            //     // setInputValue(newInputValue);
                                                            // }}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    // eslint-disable-next-line no-underscore-dangle
                                                                    <li {...props} key={option._id}>
                                                                        {option.name}
                                                                    </li>
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    name="animalTypeObject"
                                                                    placeholder="Select"
                                                                    {...params}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {loading ? (
                                                                                    <CircularProgress color="inherit" size={20} />
                                                                                ) : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                    {errors.animalTypeObject && touched.animalTypeObject && (
                                                        <FormHelperText error id="animalTypeObject-error">
                                                            {errors.animalTypeObject}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {extraFields?.find((field) => field.name === 'suitedFor') && (
                                                    <>
                                                        <FormControl>
                                                            <InputLabel htmlFor="suitedFor">Suited for</InputLabel>
                                                            <Autocomplete
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        paddingTop: '0px !important',
                                                                        paddingBottom: '0px !important'
                                                                    }
                                                                }}
                                                                id="suitedFor"
                                                                className={errors.suitedFor && touched.suitedFor ? 'error' : ''}
                                                                placeholder="Select"
                                                                size="small"
                                                                value={values.suitedFor}
                                                                onChange={(event, newValue) => {
                                                                    handleChange(event);
                                                                    if (newValue) {
                                                                        setFieldValue('suitedFor', newValue);
                                                                    } else {
                                                                        setFieldValue('suitedFor', '');
                                                                    }
                                                                }}
                                                                isOptionEqualToValue={(option: any, value) => option === value}
                                                                options={
                                                                    extraFields?.find((field) => field.name === 'suitedFor')?.value || []
                                                                }
                                                                getOptionLabel={(option: any) => option || []}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        // eslint-disable-next-line no-underscore-dangle
                                                                        <li {...props} key={option._id} aria-selected={false}>
                                                                            {option}
                                                                        </li>
                                                                    );
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        name="suitedFor"
                                                                        placeholder="Select"
                                                                        {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <>
                                                                                    {loading ? (
                                                                                        <CircularProgress color="inherit" size={20} />
                                                                                    ) : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </>
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.suitedFor && touched.suitedFor && (
                                                                <FormHelperText error id="suitedFor-error">
                                                                    {errors.suitedFor}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </>
                                                )}

                                                <FormControl>
                                                    <InputLabel htmlFor="pricing">Ads duration(Months/Price)</InputLabel>
                                                    <Select
                                                        id="pricing"
                                                        name="pricing"
                                                        className={values.pricing ? '' : 'GenderSelectPlaceholder'}
                                                        placeholder="Select"
                                                        value={values.pricing}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            const selectedMonth = event.target.value;
                                                            const selectedPricingObject = pricingList.find(
                                                                (option) => option.month === selectedMonth
                                                            );
                                                            if (selectedPricingObject) {
                                                                setPricing(selectedPricingObject);
                                                            }
                                                        }}
                                                        size="small"
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {pricingList?.map((option, index) => (
                                                            <MenuItem key={index} value={option.month}>
                                                                {`${option.month} months / $${option.price}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.pricing && touched.pricing && (
                                                        <FormHelperText error id="pricing-error">
                                                            {errors.pricing}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {chosenAnimalType.hasCategory && (
                                                    <>
                                                        <FormControl>
                                                            <InputLabel htmlFor="animalCategoryObject">Category</InputLabel>

                                                            <Autocomplete
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        paddingTop: '0px !important',
                                                                        paddingBottom: '0px !important'
                                                                    }
                                                                }}
                                                                id="animalCategoryObject"
                                                                className={
                                                                    errors.animalCategoryObject && touched.animalCategoryObject
                                                                        ? 'error'
                                                                        : ''
                                                                }
                                                                placeholder="Select"
                                                                value={values.animalCategoryObject}
                                                                size="small"
                                                                open={animalCategory}
                                                                onOpen={() => {
                                                                    setAnimalCategory(true);
                                                                }}
                                                                onClose={() => {
                                                                    setAnimalCategory(false);
                                                                    setAnimalSubCategory(false);
                                                                    setAnimalBreed(false);
                                                                    catBreedRefetch({
                                                                        input: {
                                                                            animalTypeId: chosenAnimalType._id,
                                                                            hasCategory: chosenAnimalType.hasCategory
                                                                        }
                                                                    });
                                                                }}
                                                                isOptionEqualToValue={(option: any, value) => option._id === value?._id}
                                                                getOptionLabel={(option: any) => `${option?.name || ''}`}
                                                                options={catBreedData?.ListCategoriesAndBreeds?.categoriesAndBreeds}
                                                                loading={catBreedLoading}
                                                                onChange={(event: any, newValue: any | null, reason) => {
                                                                    if (newValue) {
                                                                        setFieldValue('animalCategoryObject', newValue);
                                                                        setChosenCategory(newValue);
                                                                        setChosenSubCategory({});
                                                                        setChosenBreed({});
                                                                        setCategoryValues(newValue);
                                                                        setFieldValue('animalSubCategoryObject', {});
                                                                        setFieldValue('breedObject', {});
                                                                    } else {
                                                                        setChosenCategory({});
                                                                        setChosenSubCategory({});
                                                                        setBreedList([]);
                                                                        setFieldValue('animalCategoryObject', {});
                                                                        setFieldValue('animalSubCategoryObject', {});
                                                                        setFieldValue('breedObject', {});
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        // eslint-disable-next-line no-underscore-dangle
                                                                        <li {...props} key={option._id}>
                                                                            {option.name}
                                                                        </li>
                                                                    );
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        name="category"
                                                                        placeholder="Select"
                                                                        {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <>
                                                                                    {loading ? (
                                                                                        <CircularProgress color="inherit" size={20} />
                                                                                    ) : null}
                                                                                    {params.InputProps.endAdornment}
                                                                                </>
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.categoryObject && touched.categoryObject && (
                                                                <FormHelperText error id="category-error">
                                                                    {errors.categoryObject}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                        {chosenCategory?.hasCategory && (
                                                            <FormControl>
                                                                <InputLabel htmlFor="animalSubCategoryObject">Sub category</InputLabel>
                                                                <Autocomplete
                                                                    sx={{
                                                                        '& .MuiOutlinedInput-root': {
                                                                            paddingTop: '0px !important',
                                                                            paddingBottom: '0px !important'
                                                                        }
                                                                    }}
                                                                    id="animalSubCategoryObject"
                                                                    className={
                                                                        errors.animalSubCategoryObject && touched.animalSubCategoryObject
                                                                            ? 'error'
                                                                            : ''
                                                                    }
                                                                    placeholder="Select"
                                                                    value={values.animalSubCategoryObject}
                                                                    size="small"
                                                                    open={animalSubCategory}
                                                                    onOpen={() => {
                                                                        setAnimalSubCategory(true);
                                                                    }}
                                                                    onClose={() => {
                                                                        setAnimalSubCategory(false);
                                                                        setAnimalBreed(false);
                                                                        subCatRefetch({
                                                                            input: {
                                                                                animalTypeId: chosenAnimalType._id,
                                                                                hasCategory: chosenCategory.hasCategory,
                                                                                categoryId: chosenCategory._id,
                                                                                categoryName: chosenSubCategory.name
                                                                            }
                                                                        });
                                                                    }}
                                                                    isOptionEqualToValue={(option: any, value) => option._id === value._id}
                                                                    getOptionLabel={(option: any) => `${option.name || ''}`}
                                                                    options={subCatData?.ListCategoriesAndBreeds?.categoriesAndBreeds}
                                                                    loading={subCatLoading}
                                                                    onChange={(event: any, newValue: any | null) => {
                                                                        if (newValue) {
                                                                            setChosenSubCategory(newValue);
                                                                            setFieldValue('animalSubCategoryObject', newValue);
                                                                            setChosenBreed({});
                                                                            setFieldValue('breedObject', {});
                                                                        } else {
                                                                            setChosenSubCategory({});
                                                                            setFieldValue('animalSubCategoryObject', {});
                                                                            setFieldValue('breedObject', {});
                                                                        }
                                                                    }}
                                                                    renderOption={(props, option) => {
                                                                        return (
                                                                            // eslint-disable-next-line no-underscore-dangle
                                                                            <li {...props} key={`subcat-${option.name.replace(' ', '-')}`}>
                                                                                {option.name}
                                                                            </li>
                                                                        );
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            name="animalSubCategoryObject"
                                                                            placeholder="Select"
                                                                            {...params}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <>
                                                                                        {loading ? (
                                                                                            <CircularProgress color="inherit" size={20} />
                                                                                        ) : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.subcategoryObject && touched.subcategoryObject && (
                                                                    <FormHelperText error id="subcategory-error">
                                                                        {errors.subcategoryObject}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </>
                                                )}

                                                {(!chosenSubCategory.hasCategory || !chosenCategory.hasCategory) && (
                                                    <FormControl>
                                                        <InputLabel htmlFor="breedObject">Breed</InputLabel>
                                                        <Autocomplete
                                                            sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    paddingTop: '0px !important',
                                                                    paddingBottom: '0px !important'
                                                                }
                                                            }}
                                                            id="breedObject"
                                                            className={errors.breedObject && touched.breedObject ? 'error' : ''}
                                                            placeholder="Select"
                                                            value={values.breedObject}
                                                            size="small"
                                                            open={animalBreed}
                                                            onOpen={() => {
                                                                const param = {
                                                                    animalTypeId: animalTypesValues._id,
                                                                    hasCategory: false,
                                                                    categoryId: chosenCategory._id,
                                                                    categoryName: chosenSubCategory.name
                                                                };

                                                                if (chosenSubCategory) {
                                                                    param.categoryId = chosenCategory.id;
                                                                    param.categoryName = chosenSubCategory.name;
                                                                }
                                                                breedRefetch({
                                                                    input: param
                                                                });
                                                                setAnimalBreed(true);
                                                            }}
                                                            onClose={() => {
                                                                const param = {
                                                                    animalTypeId: animalTypesValues._id,
                                                                    hasCategory: false,
                                                                    categoryId: chosenCategory._id,
                                                                    categoryName: chosenSubCategory.name
                                                                };

                                                                if (chosenSubCategory && !chosenCategory.hasCategory) {
                                                                    param.categoryId = chosenCategory.id;
                                                                    param.categoryName = chosenSubCategory.name;
                                                                }
                                                                breedRefetch({
                                                                    input: param
                                                                });
                                                                setAnimalBreed(false);
                                                            }}
                                                            isOptionEqualToValue={(option: any, value) => option._id === value._id}
                                                            getOptionLabel={(option: any) => `${option.name || ''}`}
                                                            options={breedList || []}
                                                            loading={breedLoading}
                                                            onChange={(event: any, newValue: any | null) => {
                                                                if (newValue) {
                                                                    setChosenBreed(newValue);
                                                                    setFieldValue('breedObject', newValue);
                                                                } else {
                                                                    setChosenBreed([]);
                                                                    setFieldValue('breedObject', {});
                                                                }
                                                            }}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    // eslint-disable-next-line no-underscore-dangle
                                                                    <li {...props} aria-selected={false}>
                                                                        {option.name}
                                                                    </li>
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    name="breedObject"
                                                                    placeholder="Select"
                                                                    {...params}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {loading ? (
                                                                                    <CircularProgress color="inherit" size={20} />
                                                                                ) : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.breedObject && touched.breedObject && (
                                                            <FormHelperText error id="breed-error">
                                                                {errors.breedObject}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                )}

                                                <FormControl>
                                                    <InputLabel htmlFor="pureBred">Purebred</InputLabel>
                                                    <Select
                                                        id="pureBred"
                                                        name="pureBred"
                                                        className={values.pureBred ? '' : 'GenderSelectPlaceholder'}
                                                        placeholder="Select"
                                                        value={values.pureBred}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                        }}
                                                        size="small"
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {pureBredOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.pureBred && touched.pureBred && (
                                                        <FormHelperText error id="pureBred-error">
                                                            {errors.pureBred}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                                <FormControl>
                                                    {!!initialValues.picNumber && (
                                                        <>
                                                            <InputLabel htmlFor="picNumber">Pic Number</InputLabel>
                                                            <TextField
                                                                size="small"
                                                                placeholder="Pic number"
                                                                value={values.picNumber}
                                                                name="picNumber"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => setFieldValue('picNumber', event.target.value)}
                                                            />
                                                            {touched.picNumber && errors.picNumber && (
                                                                <FormHelperText error id="picNumber-error">
                                                                    {errors.picNumber}
                                                                </FormHelperText>
                                                            )}
                                                        </>
                                                    )}
                                                </FormControl>
                                                <FormControl>
                                                    {!!initialValues.microchipNumber && (
                                                        <>
                                                            <InputLabel htmlFor="microchipNumber">Microchip number</InputLabel>
                                                            <TextField
                                                                size="small"
                                                                placeholder="Microchip number"
                                                                value={values.microchipNumber}
                                                                name="microchipNumber"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => setFieldValue('microchipNumber', event.target.value)}
                                                            />
                                                            {touched.microchipNumber && errors.microchipNumber && (
                                                                <FormHelperText error id="microchipNumber-error">
                                                                    {errors.microchipNumber}
                                                                </FormHelperText>
                                                            )}
                                                        </>
                                                    )}
                                                </FormControl>

                                                <FormControl>
                                                    <InputLabel>Show on my profile</InputLabel>
                                                    <RadioGroup
                                                        aria-labelledby="showOnProfile"
                                                        defaultValue={initialValues.showOnProfile}
                                                        value={values.showOnProfile}
                                                        name="showOnProfile"
                                                        onChange={onChangeShowRadio}
                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                    >
                                                        {showOnProfile.map((option, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                control={
                                                                    <Radio
                                                                        name="showOnProfile"
                                                                        value={option.value}
                                                                        // checked={option.value === showRadio}
                                                                    />
                                                                }
                                                                label={option.label}
                                                                onChange={(event) => setFieldValue('showOnProfile', option.value)}
                                                                style={{ marginRight: '30px' }}
                                                            />
                                                        ))}
                                                        <IconButton onClick={handlePopupOpen} style={{ marginLeft: 'auto' }}>
                                                            <InfoIcon style={{ color: '#cb3d19' }} />
                                                        </IconButton>
                                                    </RadioGroup>
                                                    {errors.showOnProfile && (
                                                        <FormHelperText error id="showOnProfile-error">
                                                            {errors.showOnProfile}
                                                        </FormHelperText>
                                                    )}
                                                    <Dialog open={isProfilePopupOpen} onClose={handlePopupClose} maxWidth="xs">
                                                        <DialogTitle>Show on my profile</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>
                                                                Your newly added animal will now be displayed on your profile for others to
                                                                see.
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <Divider />
                                                        <Button
                                                            onClick={handlePopupClose}
                                                            style={{
                                                                marginLeft: 'auto',
                                                                marginRight: '15px',
                                                                border: 'none',
                                                                backgroundColor: 'transparent',
                                                                // textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                                position: 'relative',
                                                                float: 'right',
                                                                clear: 'both',
                                                                marginBottom: '2px',
                                                                color: '#cb3d19'
                                                            }}
                                                        >
                                                            Close
                                                        </Button>
                                                    </Dialog>
                                                </FormControl>

                                                {/* <FormControl>
                                                    <Typography variant="h3" component="div" marginTop="1rem" className="color-red h4">
                                                        Contact preferences
                                                    </Typography>
                                                </FormControl> */}

                                                {/* <FormControl>
                                                    <InputLabel htmlFor="enquiryType">Enquiry preference</InputLabel>
                                                    <Select
                                                        id="enquiryType"
                                                        name="enquiryType"
                                                        className={values.enquiryType ? '' : 'GenderSelectPlaceholder'}
                                                        placeholder="Select"
                                                        value={values.enquiryType}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            handleEnquiryTypeChange(event);
                                                            setFieldValue('enquiryValue', '');
                                                        }}
                                                        size="small"
                                                        input={<OutlinedInput />}
                                                        MenuProps={MenuProps}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {enquiryOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.enquiryType && touched.enquiryType && (
                                                        <FormHelperText error id="enquiryType-error">
                                                            {errors.enquiryType}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {(enquiryTypeModified || values?.enquiryValue) && (
                                                    <FormControl>
                                                        <InputLabel htmlFor="enquiryValue">
                                                            {values.enquiryType === 'Text' && 'Phone Number'}
                                                            {values.enquiryType === 'Phone' && 'Phone Number'}
                                                            {values.enquiryType === 'Email' && 'Email Address'}
                                                        </InputLabel>
                                                        {values.enquiryType === 'EMAIL' ? (
                                                            <TextField
                                                                size="small"
                                                                id="enquiryValue"
                                                                name="enquiryValue"
                                                                type={values.enquiryType === 'Email' ? 'email' : 'text'}
                                                                value={values.enquiryValue}
                                                                onChange={handleChange}
                                                                placeholder={getEnquiryPlaceholder(values.enquiryType)}
                                                            />
                                                        ) : (
                                                            (values.enquiryType === 'PHONE' || values.enquiryType === 'TEXT') && (
                                                                <PhoneInput
                                                                    defaultCountry="ua"
                                                                    value={values.enquiryValue}
                                                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                                                    onChange={(phone) => setFieldValue('enquiryValue', phone)}
                                                                    placeholder={getEnquiryPlaceholder(values.enquiryType)}
                                                                />
                                                            )
                                                        )}
                                                        {errors.enquiryValue && touched.enquiryValue && (
                                                            <FormHelperText error id="enquiryValue-error">
                                                                {errors.enquiryValue}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl> */}
                                                {/* )} */}

                                                <Stack>
                                                    <ContactPreference
                                                        handleAddField={(index) => {
                                                            setFieldValue('enquiryPreference', [
                                                                ...values.enquiryPreference,
                                                                { index, enquiryType: '', enquiryValue: '' }
                                                            ]);
                                                        }}
                                                        handleDelete={(index) => {
                                                            const newOption = values.enquiryPreference.filter(
                                                                (item) => item.index !== index
                                                            );

                                                            setFieldValue('enquiryPreference', newOption);
                                                        }}
                                                        handelTypeChange={(index, type) => {
                                                            const mappedVal = values.enquiryPreference.reduce((pre: string[], current) => {
                                                                return [...pre, current.enquiryType];
                                                            }, []);

                                                            if (mappedVal.includes(type)) {
                                                                dispatch(
                                                                    openSnackbar({
                                                                        open: true,
                                                                        anchorOrigin: { horizontal: 'center' },
                                                                        variant: 'alert',
                                                                        message: `Can't select one type more than one`,
                                                                        alert: {
                                                                            color: 'error'
                                                                        }
                                                                    })
                                                                );

                                                                return;
                                                            }

                                                            if (index >= 0) {
                                                                const updated = values.enquiryPreference.map((val, indx) => {
                                                                    if (val.index !== index) {
                                                                        return { ...val };
                                                                    }
                                                                    return {
                                                                        ...val,
                                                                        enquiryType: type,
                                                                        enquiryValue:
                                                                            // eslint-disable-next-line
                                                                            type !== 'ENQUIRE_IN_APP'
                                                                                ? type === 'EMAIL'
                                                                                    ? user?.email
                                                                                    : user?.mobile
                                                                                : ''
                                                                    };
                                                                });

                                                                setFieldValue('enquiryPreference', updated);
                                                            }
                                                        }}
                                                        handleChange={(index, value) => {
                                                            if (index >= 0) {
                                                                const updated = values.enquiryPreference.map((val) => {
                                                                    if (index !== val?.index) {
                                                                        return { ...val };
                                                                    }
                                                                    return { ...val, enquiryValue: value };
                                                                });

                                                                setFieldValue('enquiryPreference', updated);
                                                            }
                                                        }}
                                                        values={values?.enquiryPreference}
                                                        errors={errors?.enquiryPreference}
                                                    />
                                                    {values?.enquiryPreference?.length === 0 && (
                                                        <FormHelperText error id="showOnProfile-error">
                                                            {errors?.enquiryPreference}
                                                        </FormHelperText>
                                                    )}
                                                </Stack>

                                                <Stack>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            style={{ paddingLeft: 0, paddingRight: 0 }}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="div"
                                                                marginTop="1rem"
                                                                className="color-red h4"
                                                            >
                                                                Additional information
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <Stack gap={2}>
                                                                <FormControl>
                                                                    {extraFields?.find((field) => field.name === 'microchipNumber') && (
                                                                        <>
                                                                            <InputLabel htmlFor="microchipNumber">
                                                                                Microchip number
                                                                            </InputLabel>
                                                                            <TextField
                                                                                size="small"
                                                                                placeholder="Microchip number"
                                                                                value={values.microchipNumber}
                                                                                name="microchipNumber"
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) =>
                                                                                    setFieldValue('microchipNumber', event.target.value)
                                                                                }
                                                                            />
                                                                            {touched.microchipNumber && errors.microchipNumber && (
                                                                                <FormHelperText error id="microchipNumber-error">
                                                                                    {errors.microchipNumber}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </FormControl>
                                                                <FormControl>
                                                                    {extraFields?.find((field) => field.name === 'idNumber') && (
                                                                        <>
                                                                            <InputLabel htmlFor="identificationNumber">
                                                                                ID/ Tag number
                                                                            </InputLabel>
                                                                            <TextField
                                                                                size="small"
                                                                                placeholder="Identification number"
                                                                                value={values.identificationNumber}
                                                                                name="identificationNumber"
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) =>
                                                                                    setFieldValue(
                                                                                        'identificationNumber',
                                                                                        event.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            {touched.identificationNumber && errors.identificationNumber && (
                                                                                <FormHelperText error id="identificationNumber-error">
                                                                                    {errors.identificationNumber}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </FormControl>
                                                                <FormControl>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            label="DOB"
                                                                            value={
                                                                                values.dateOfBirth &&
                                                                                dayjs(values?.dateOfBirth).startOf('day')
                                                                            }
                                                                            maxDate={
                                                                                values.toDateLocal
                                                                                    ? dayjs(subtractOneDay(values?.toDateLocal))
                                                                                    : undefined
                                                                            }
                                                                            onChange={(value: Dayjs | null) => {
                                                                                setFieldValue('dateOfBirth', value?.toDate());
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                            inputFormat="DD/MM/YYYY"
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>

                                                                {extraFields?.find((field) => field.name === 'breederId') && (
                                                                    <>
                                                                        <FormControl>
                                                                            <InputLabel htmlFor="breederId">Breeder ID</InputLabel>
                                                                            <TextField
                                                                                size="small"
                                                                                placeholder="Breeder ID"
                                                                                value={values.breederId}
                                                                                name="breederId"
                                                                                onBlur={handleBlur}
                                                                                onChange={(event) =>
                                                                                    setFieldValue('breederId', event.target.value)
                                                                                }
                                                                            />
                                                                            {touched.breederId && errors.breederId && (
                                                                                <FormHelperText error id="breederId-error">
                                                                                    {errors.breederId}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </>
                                                                )}

                                                                {extraFields?.find((field) => field.name === 'handRaised') && (
                                                                    <>
                                                                        <FormControl>
                                                                            <InputLabel htmlFor="handRaised">Hand raised</InputLabel>
                                                                            <Select
                                                                                id="handRaised"
                                                                                name="handRaised"
                                                                                className={
                                                                                    values.handRaised ? '' : 'GenderSelectPlaceholder'
                                                                                }
                                                                                placeholder="Select"
                                                                                value={values.handRaised}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                }}
                                                                                size="small"
                                                                                input={<OutlinedInput />}
                                                                                MenuProps={MenuProps}
                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                            >
                                                                                {handRaisedOptions.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                            {errors.handRaised && touched.handRaised && (
                                                                                <FormHelperText error id="handRaised-error">
                                                                                    {errors.handRaised}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </>
                                                                )}

                                                                {extraFields?.find((field) => field.name === 'chickensBantam') && (
                                                                    <>
                                                                        <FormControl>
                                                                            <InputLabel htmlFor="chickensBantam">
                                                                                Bantam/Standard Large
                                                                            </InputLabel>
                                                                            <Autocomplete
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        paddingTop: '0px !important',
                                                                                        paddingBottom: '0px !important'
                                                                                    }
                                                                                }}
                                                                                id="chickensBantam"
                                                                                className={
                                                                                    errors.chickensBantam && touched.chickensBantam
                                                                                        ? 'error'
                                                                                        : ''
                                                                                }
                                                                                placeholder="Select"
                                                                                size="small"
                                                                                value={values.chickensBantam}
                                                                                onChange={(event, newValue) => {
                                                                                    handleChange(event);
                                                                                    setFieldValue('chickensBantam', newValue);
                                                                                }}
                                                                                isOptionEqualToValue={(option: any, value) =>
                                                                                    option === value
                                                                                }
                                                                                options={
                                                                                    extraFields?.find(
                                                                                        (field) => field.name === 'chickensBantam'
                                                                                    )?.value || []
                                                                                }
                                                                                getOptionLabel={(option: any) => option || []}
                                                                                renderOption={(props, option) => {
                                                                                    return (
                                                                                        // eslint-disable-next-line no-underscore-dangle
                                                                                        <li
                                                                                            {...props}
                                                                                            key={option._id}
                                                                                            aria-selected={false}
                                                                                        >
                                                                                            {option.label}
                                                                                        </li>
                                                                                    );
                                                                                }}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        name="chickensBantam"
                                                                                        placeholder="Select"
                                                                                        {...params}
                                                                                        InputProps={{
                                                                                            ...params.InputProps,
                                                                                            endAdornment: (
                                                                                                <>
                                                                                                    {loading ? (
                                                                                                        <CircularProgress
                                                                                                            color="inherit"
                                                                                                            size={20}
                                                                                                        />
                                                                                                    ) : null}
                                                                                                    {params.InputProps.endAdornment}
                                                                                                </>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            {errors.chickensBantam && touched.chickensBantam && (
                                                                                <FormHelperText error id="chickensBantam-error">
                                                                                    {errors.chickensBantam}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </>
                                                                )}

                                                                {extraFields?.find((field) => field.name === 'geneticTraits') && (
                                                                    <>
                                                                        <FormControl>
                                                                            <InputLabel htmlFor="geneticTraits">Genetic traits</InputLabel>
                                                                            <Autocomplete
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        paddingTop: '0px !important',
                                                                                        paddingBottom: '0px !important'
                                                                                    }
                                                                                }}
                                                                                id="geneticTraits"
                                                                                className={
                                                                                    errors.geneticTraits && touched.geneticTraits
                                                                                        ? 'error'
                                                                                        : ''
                                                                                }
                                                                                placeholder="Select"
                                                                                size="small"
                                                                                value={values.geneticTraits}
                                                                                onChange={(event, newValue) => {
                                                                                    handleChange(event);
                                                                                    if (newValue) {
                                                                                        setFieldValue('geneticTraits', newValue);
                                                                                    } else {
                                                                                        setFieldValue('geneticTraits', '');
                                                                                    }
                                                                                }}
                                                                                isOptionEqualToValue={(option: any, value) =>
                                                                                    option === value
                                                                                }
                                                                                options={
                                                                                    extraFields?.find(
                                                                                        (field) => field.name === 'geneticTraits'
                                                                                    )?.value || []
                                                                                }
                                                                                getOptionLabel={(option: any) => option || []}
                                                                                renderOption={(props, option) => {
                                                                                    return (
                                                                                        // eslint-disable-next-line no-underscore-dangle
                                                                                        <li
                                                                                            {...props}
                                                                                            key={option._id}
                                                                                            aria-selected={false}
                                                                                        >
                                                                                            {option}
                                                                                        </li>
                                                                                    );
                                                                                }}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        name="geneticTraits"
                                                                                        placeholder="Select"
                                                                                        {...params}
                                                                                        InputProps={{
                                                                                            ...params.InputProps,
                                                                                            endAdornment: (
                                                                                                <>
                                                                                                    {loading ? (
                                                                                                        <CircularProgress
                                                                                                            color="inherit"
                                                                                                            size={20}
                                                                                                        />
                                                                                                    ) : null}
                                                                                                    {params.InputProps.endAdornment}
                                                                                                </>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            {errors.geneticTraits && touched.geneticTraits && (
                                                                                <FormHelperText error id="geneticTraits-error">
                                                                                    {errors.geneticTraits}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </>
                                                                )}

                                                                <FormControl>
                                                                    <InputLabel htmlFor="desexed">Desexed</InputLabel>
                                                                    <Select
                                                                        id="desexed"
                                                                        name="desexed"
                                                                        className={errors.desexed && touched.desexed ? 'error' : ''}
                                                                        placeholder="Select"
                                                                        onChange={(event) => {
                                                                            setFieldValue('desexed', event.target.value);
                                                                        }}
                                                                        // onChange={handleChange}
                                                                        input={<OutlinedInput />}
                                                                        value={values.desexed}
                                                                        renderValue={(selected) => {
                                                                            const desexedEnquiry = desexedOptions.find(
                                                                                (option) => option.value === selected
                                                                            );
                                                                            if (desexedEnquiry) {
                                                                                // Capitalize the first letter of the label
                                                                                const capitalizedLabel =
                                                                                    desexedEnquiry.label.charAt(0).toUpperCase() +
                                                                                    desexedEnquiry.label.slice(1).toLowerCase();
                                                                                return capitalizedLabel;
                                                                            }
                                                                            return '';
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                    >
                                                                        {desexedOptions.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {errors.desexed && touched.desexed && (
                                                                        <FormHelperText error id="desexed-error">
                                                                            {errors.desexed}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>

                                                                <FormControl>
                                                                    <Typography
                                                                        variant="h3"
                                                                        component="div"
                                                                        marginTop="1rem"
                                                                        marginBottom="1rem"
                                                                        className="color-red h4"
                                                                    >
                                                                        Add lineage details
                                                                    </Typography>
                                                                </FormControl>

                                                                <FormControl>
                                                                    {(values?.father?.image !== '' ||
                                                                        values?.fatherDetail?.image ||
                                                                        fatherLineagephoto) && (
                                                                        <Card
                                                                            sx={{
                                                                                maxWidth: 345,
                                                                                display: 'inline-block',
                                                                                position: 'relative',
                                                                                margin: '0 0 1.5rem'
                                                                            }}
                                                                        >
                                                                            {sireDialogOpen === false &&
                                                                                values?.father &&
                                                                                (values?.father?.image || values?.father?.media) &&
                                                                                values?.fatherId && (
                                                                                    <Box className="image-with-caption">
                                                                                        <Box
                                                                                            component="span"
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: 8,
                                                                                                right: 10,
                                                                                                zIndex: 1
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                setFieldValue('father', {});
                                                                                                setFieldValue('fatherDetail', {});
                                                                                                setFieldValue('fatherId', '');
                                                                                                setFatherLineagePhoto('');
                                                                                                setClearChildFatherState(true);
                                                                                            }}
                                                                                        >
                                                                                            <IconDismissSquare />
                                                                                        </Box>
                                                                                        <img
                                                                                            height="194"
                                                                                            src={
                                                                                                fatherLineagephoto &&
                                                                                                fatherLineagephoto?.length > 0
                                                                                                    ? fatherLineagephoto
                                                                                                    : values?.father?.image
                                                                                            }
                                                                                            alt=""
                                                                                            onClick={() => openLineageDialog('father')}
                                                                                        />
                                                                                        <span className="sliderCaption">
                                                                                            {values?.father?.listingName} - Sire/Father
                                                                                        </span>
                                                                                    </Box>
                                                                                )}
                                                                            {sireDialogOpen === false &&
                                                                                values?.fatherDetail &&
                                                                                (values?.fatherDetail?.hasOwnProperty('image') ||
                                                                                    values?.fatherDetail?.hasOwnProperty('media')) &&
                                                                                (values?.fatherDetail?.image !== '' ||
                                                                                    values?.fatherDetail?.media !== '') &&
                                                                                !values?.fatherId && (
                                                                                    <Box className="image-with-caption">
                                                                                        <Box
                                                                                            component="span"
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: 8,
                                                                                                right: 10,
                                                                                                zIndex: 1
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                setFieldValue('father', {});
                                                                                                setFieldValue('fatherDetail', {});
                                                                                                setFatherLineagePhoto('');
                                                                                                setClearChildFatherState(true);
                                                                                            }}
                                                                                        >
                                                                                            <IconDismissSquare />
                                                                                        </Box>
                                                                                        <img
                                                                                            height="194"
                                                                                            src={
                                                                                                fatherLineagephoto
                                                                                                    ? fatherLineagephoto
                                                                                                    : URL.createObjectURL(
                                                                                                          values?.fatherDetail?.image
                                                                                                      )
                                                                                            }
                                                                                            alt=""
                                                                                            onClick={() => openLineageDialog('father')}
                                                                                        />
                                                                                        <span className="sliderCaption">
                                                                                            {values?.fatherDetail?.listingName} -
                                                                                            Sire/Father
                                                                                        </span>
                                                                                    </Box>
                                                                                )}
                                                                        </Card>
                                                                    )}
                                                                    <>
                                                                        {(!values?.father?.hasOwnProperty('image') ||
                                                                            values?.father?.image === '') &&
                                                                            !values?.fatherDetail?.hasOwnProperty('image') &&
                                                                            !fatherLineagephoto && (
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    size="large"
                                                                                    color="secondary"
                                                                                    style={{ marginBottom: '10px' }}
                                                                                    onClick={() => openLineageDialog('father')}
                                                                                >
                                                                                    <span style={{ color: '#45110B' }}>
                                                                                        Add Sire/Father
                                                                                    </span>
                                                                                </Button>
                                                                            )}
                                                                        {sireDialogOpen && (
                                                                            <AnimalDialog
                                                                                title="Add Sire Details"
                                                                                openDialog={sireDialogOpen}
                                                                                name="father"
                                                                                gender="Male"
                                                                                userId={values.userId}
                                                                                setField={setFieldValue}
                                                                                placeholder="Select Father"
                                                                                classes=" "
                                                                                handleDialogClose={() => closeLineageDialog('sire')}
                                                                                clearChildFatherState={clearChildFatherState}
                                                                                setClearChildFatherState={setClearChildFatherState}
                                                                                clearChildMotherState={clearChildMotherState}
                                                                                setClearChildMotherState={setClearChildMotherState}
                                                                                values={values}
                                                                                setFatherLineagePhoto={setFatherLineagePhoto}
                                                                                setMotherLineagePhoto={setMotherLineagePhoto}
                                                                            />
                                                                        )}
                                                                    </>
                                                                </FormControl>

                                                                <FormControl>
                                                                    {(values?.mother?.image !== '' ||
                                                                        values?.motherDetail?.image ||
                                                                        motherLineagephoto) && (
                                                                        <Card
                                                                            sx={{
                                                                                maxWidth: 345,
                                                                                display: 'inline-block',
                                                                                position: 'relative',
                                                                                margin: '0 0 1.5rem'
                                                                            }}
                                                                        >
                                                                            {damDialogOpen === false &&
                                                                                values?.mother &&
                                                                                (values?.mother?.image || values?.mother?.media) &&
                                                                                values?.motherId && (
                                                                                    <Box className="image-with-caption">
                                                                                        <Box
                                                                                            component="span"
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: 8,
                                                                                                right: 10,
                                                                                                zIndex: 1
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                setFieldValue('mother', {});
                                                                                                setFieldValue('motherDetail', {});
                                                                                                setFieldValue('motherId', '');
                                                                                                setMotherLineagePhoto('');
                                                                                                setClearChildMotherState(true);
                                                                                            }}
                                                                                        >
                                                                                            <IconDismissSquare />
                                                                                        </Box>
                                                                                        <img
                                                                                            height="194"
                                                                                            src={
                                                                                                motherLineagephoto &&
                                                                                                motherLineagephoto?.length > 0
                                                                                                    ? motherLineagephoto
                                                                                                    : values?.mother?.image
                                                                                            }
                                                                                            alt=""
                                                                                            onClick={() => openLineageDialog('dam')}
                                                                                        />
                                                                                        <span className="sliderCaption">
                                                                                            {values?.mother?.listingName} - Dam/Mother
                                                                                        </span>
                                                                                    </Box>
                                                                                )}
                                                                            {damDialogOpen === false &&
                                                                                values?.motherDetail &&
                                                                                (values?.motherDetail?.hasOwnProperty('image') ||
                                                                                    values?.motherDetail?.hasOwnProperty('media')) &&
                                                                                (values?.motherDetail?.image !== '' ||
                                                                                    values?.motherDetail?.media !== '') &&
                                                                                !values?.motherId && (
                                                                                    <Box className="image-with-caption">
                                                                                        <Box
                                                                                            component="span"
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                top: 8,
                                                                                                right: 10,
                                                                                                zIndex: 1
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                setFieldValue('mother', {});
                                                                                                setFieldValue('motherDetail', {});
                                                                                                setMotherLineagePhoto('');
                                                                                                setClearChildMotherState(true);
                                                                                            }}
                                                                                        >
                                                                                            <IconDismissSquare />
                                                                                        </Box>
                                                                                        <img
                                                                                            height="194"
                                                                                            src={
                                                                                                motherLineagephoto
                                                                                                    ? motherLineagephoto
                                                                                                    : URL.createObjectURL(
                                                                                                          values?.motherDetail?.image
                                                                                                      )
                                                                                            }
                                                                                            alt=""
                                                                                            onClick={() => openLineageDialog('dam')}
                                                                                        />
                                                                                        <span className="sliderCaption">
                                                                                            {values?.motherDetail?.listingName} - Dam/Mother
                                                                                        </span>
                                                                                    </Box>
                                                                                )}
                                                                        </Card>
                                                                    )}
                                                                    <>
                                                                        {(!values?.mother?.hasOwnProperty('image') ||
                                                                            values?.mother?.image === '') &&
                                                                            !values?.motherDetail?.hasOwnProperty('image') &&
                                                                            !motherLineagephoto && (
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    size="large"
                                                                                    color="secondary"
                                                                                    onClick={() => openLineageDialog('dam')}
                                                                                >
                                                                                    <span style={{ color: '#45110B' }}>Add Dam/Mother</span>
                                                                                </Button>
                                                                            )}
                                                                        {damDialogOpen && (
                                                                            <AnimalDialog
                                                                                title="Add Dam/Mother Details"
                                                                                openDialog={damDialogOpen}
                                                                                name="dam"
                                                                                gender="Female"
                                                                                userId={values.userId}
                                                                                setField={setFieldValue}
                                                                                placeholder="Select Mother"
                                                                                classes=" "
                                                                                handleDialogClose={() => closeLineageDialog('dam')}
                                                                                clearChildMotherState={clearChildMotherState}
                                                                                setClearChildMotherState={setClearChildMotherState}
                                                                                clearChildFatherState={clearChildFatherState}
                                                                                setClearChildFatherState={setClearChildFatherState}
                                                                                values={values}
                                                                                setFatherLineagePhoto={setFatherLineagePhoto}
                                                                                setMotherLineagePhoto={setMotherLineagePhoto}
                                                                            />
                                                                        )}
                                                                    </>
                                                                </FormControl>
                                                            </Stack>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Stack>
                                            </Stack>
                                            <Divider />
                                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2.75}>
                                                <Button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    color="secondary"
                                                >
                                                    Next
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
                <VideoDialog
                    openDialog={videoDialogOpen}
                    // photos={photo}
                    // settingPhoto={setPhoto}
                    handleDialogClose={closeVideo}
                    imageUrl={videoUrl}
                />
            </MainCard>
        </>
    );
};

export const GridContainer = styled(({ ...props }) => <Grid {...props} />)`
    width: 100%;
    @media (min-width: 600px) {
        width: 60%;
    }

    @media (min-width: 1024px) {
        width: 50%;
    }

    @media (min-width: 1440px) {
        width: 40%;
    } ;
`;

export default EditSalesAnimal;
