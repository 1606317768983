import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import EditSalesAnimal from 'views/forSale/forms/edit-sales-animal-listing';
import Setting from 'views/settings';
import EnquiryFormTemplates from 'views/enquiryTemplates';
import EditEnquiryTemplate from 'views/enquiryTemplates/forms/edit-enquiry-template';
import AddEnquiryTemplate from 'views/enquiryTemplates/forms/add-enquiry-template';
import PinnedAnimals from 'views/pin-animals';
import BreedersTable from 'views/pin-animals/breeders-table';
import AnimalsPetsIndex from 'views/pin-animals/pets.index';
import AnimalsLivestockIndex from 'views/pin-animals/livesotck-index';
import { AuthAccessProvider } from 'contexts/AuthAccessContext';
import { AnimalTypePaths, ClubAssociationPaths } from 'common/paths';
import CategoryList from 'views/breedManagement/category';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));
const WelcomeDashboard = Loadable(lazy(() => import('views/dashboard/welcome')));

// menu routing
const MenuList = Loadable(lazy(() => import('views/menu/menusList')));
const AddMenu = Loadable(lazy(() => import('views/menu/addMenu')));
const EditMenu = Loadable(lazy(() => import('views/menu/editMenu')));

// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));
const AdvancedPageList = Loadable(lazy(() => import('views/pageManagement/advancedPage')));
const AddPage = Loadable(lazy(() => import('views/pageManagement/forms/addPage')));
const EditPage = Loadable(lazy(() => import('views/pageManagement/forms/editPage/index')));

// User managemenet routing
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/forms/add-admin')));
const AdminEdit = Loadable(lazy(() => import('views/userManagement/admins/forms/edit-admin')));
const AdminView = Loadable(lazy(() => import('views/userManagement/admins/forms/view-admin')));

const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));
const UserList = Loadable(lazy(() => import('views/userManagement/users')));
const UserProfile = Loadable(lazy(() => import('views/userManagement/users/profile')));
const AppUserList = Loadable(lazy(() => import('views/userManagement/appUsers')));
const AppUserProfile = Loadable(lazy(() => import('views/userManagement/appUsers/profile')));
const EditAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/EditAppUser')));

/* faq */
const FAQList = Loadable(lazy(() => import('views/FAQ')));
const AddFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/addForm/v1')));
const EditFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/editForm/v1/EditFAQ')));
const ManageFAQ = Loadable(lazy(() => import('views/FAQ/tables')));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import('views/testimonials')));
const AddTestimonials = Loadable(lazy(() => import('views/testimonials/forms/AddTestimonials')));
const EditTestimonials = Loadable(lazy(() => import('views/testimonials/forms/EditTestimonials')));

/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

/* activity log */
const ActivityLogList = Loadable(lazy(() => import('views/activityLog')));
const ActivityLogsDetail = Loadable(lazy(() => import('views/activityLog/form/activity-logs.details.')));

/* report forms */
const ReportForm = Loadable(lazy(() => import('views/reportForm')));
const ReportFormDetails = Loadable(lazy(() => import('views/reportForm/form/report-details')));

/* packages */
const PackageList = Loadable(lazy(() => import('views/packages')));

/* account subscription */
const AccountSubscriptionList = Loadable(lazy(() => import('views/accountSubscriptions')));
const AccountSubscriptionDetails = Loadable(lazy(() => import('views/accountSubscriptions/form/account-subscription.details')));

/* transaction history */
const TransactionHistoryList = Loadable(lazy(() => import('views/transactionHistory')));
const TransactionHistoryDetails = Loadable(lazy(() => import('views/transactionHistory/form/transaction-history.details')));

/* notification */
const AllNotificationList = Loadable(lazy(() => import('layout/MainLayout/Header/NotificationSection/tables/NotificationTable')));

/* animal */
const AnimalsList = Loadable(lazy(() => import('views/animals')));
const EditAnimal = Loadable(lazy(() => import('views/animals/forms/edit-animal')));
const ViewAnimal = Loadable(lazy(() => import('views/animals/forms/view-animal-detail')));
const AddAnimal = Loadable(lazy(() => import('views/animals/forms/add-animal')));
const PinAnimal = Loadable(lazy(() => import('views/animals/pin')));

/* for sale */
const ForSaleList = Loadable(lazy(() => import('views/forSale')));
const ViewSalesAnimal = Loadable(lazy(() => import('views/forSale/forms/view-sales-detail')));
const AddSalesAnimal = Loadable(lazy(() => import('views/forSale/forms/add-sales-animal-listing')));
/* events */
const EventsList = Loadable(lazy(() => import('views/events')));

// Mock component
const MockComponent = Loadable(lazy(() => import('views/mock')));

// Edit Service
const EditService = Loadable(lazy(() => import('views/animal-service/edit-animal-service')));

/* Club */
const ClubList = Loadable(lazy(() => import('views/clubAssociation')));
const AddEditClub = Loadable(lazy(() => import('views/clubAssociation/forms/AddEditClub')));
const ClubDetails = Loadable(lazy(() => import('views/clubAssociation/view/index')));

/* Breed Management */
// const BreedManagement = Loadable(lazy(() => import('views/breedManagement')));
const AnimalTypeList = Loadable(lazy(() => import('views/breedManagement/animalType')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthAccessProvider>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </AuthAccessProvider>
    ),
    children: [
        {
            path: 'dashboard',
            element: <WelcomeDashboard />
        },
        {
            path: 'default',
            element: <DashboardDefault />
        },
        // {
        //     path: 'dashboard/welcome',
        //     element: <WelcomeDashboard />
        // },
        {
            path: 'menu/list',
            element: <MenuList />
        },
        {
            path: 'menu/add',
            element: <AddMenu />
        },
        {
            path: 'menu/edit/:id',
            element: <EditMenu />
        },
        {
            path: 'page-management/add',
            element: <AddPage />
        },
        {
            path: 'page-management/edit/:id',
            element: <EditPage />
        },
        {
            path: 'page-management/list',
            element: <PageList />
        },
        {
            path: 'page-management/add/advance',
            element: <AdvancedPageList />
        },
        {
            path: 'user/list',
            element: <UserList />
        },
        {
            path: 'user/profile/:id',
            element: <UserProfile />
        },
        {
            path: 'app-user/list',
            element: <AppUserList />
        },
        /* {
            path: 'app-user/add',
            element: <AddAppUser />
        }, */
        {
            path: 'app-user/edit/:id',
            element: <EditAppUser />
        },
        {
            path: 'app-user/profile/:id',
            element: <AppUserProfile />
        },
        {
            path: 'admin/list',
            element: <AdminList />
        },
        {
            path: 'admin/add',
            element: <AdminAdd />
        },
        {
            path: 'admin/edit/:id',
            element: <AdminEdit />
        },
        {
            path: 'admin/view/:id',
            element: <AdminView />
        },
        {
            path: 'admin/profile',
            element: <AdminProfile />
        },
        {
            path: 'admin/account-profile',
            element: <AdminAccountProfile />
        },
        {
            path: 'admin/change-password',
            element: <AdminChangePassword />
        },
        {
            path: 'faq/list',
            element: <FAQList />
        },
        {
            path: 'faq/add',
            element: <AddFAQV1 />
        },
        {
            path: 'faq/edit/:id',
            element: <EditFAQV1 />
        },
        {
            path: 'faq/manage-faq',
            element: <ManageFAQ />
        },
        {
            path: 'email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: 'email-template/add',
            element: <AddEmailTemplate />
        },
        {
            path: 'email-template/edit/:id',
            element: <EditEmailTemplate />
        },
        {
            path: '/testimonials/list',
            element: <TestimonialsList />
        },
        {
            path: 'testimonials/add',
            element: <AddTestimonials />
        },
        {
            path: 'testimonials/edit/:id',
            element: <EditTestimonials />
        },
        {
            path: 'mock',
            element: <MockComponent />
        },
        {
            path: 'activity-log/list',
            element: <ActivityLogList />
        },
        {
            path: 'activity-log/list/:id',
            element: <ActivityLogsDetail />
        },
        {
            path: 'report-form/list',
            element: <ReportForm />
        },
        {
            path: 'report-form/list/:id',
            element: <ReportFormDetails />
        },
        {
            path: 'packages/list',
            element: <PackageList />
        },
        {
            path: 'transaction-history/list',
            element: <TransactionHistoryList />
        },
        {
            path: 'transaction-history/list/:id',
            element: <TransactionHistoryDetails />
        },
        {
            path: 'account-subscriptions/list',
            element: <AccountSubscriptionList />
        },
        {
            path: 'account-subscriptions/list/:id',
            element: <AccountSubscriptionDetails />
        },
        // {
        //     path: 'app-users/list',
        //     element: <AppUsersList />
        // },
        {
            path: 'animals/list',
            element: <AnimalsList />
        },
        {
            path: 'animals/about/:id',
            element: <ViewAnimal />
        },
        {
            path: 'for-sale/list',
            element: <ForSaleList />
        },
        {
            path: 'app-user/profile/add/:id',
            element: <AddSalesAnimal />
        },
        {
            path: 'for-sale/edit/:id',
            element: <EditSalesAnimal />
        },
        {
            path: 'for-sale/service/edit/:id',
            element: <EditService />
        },
        {
            path: 'for-sale/about/:id',
            element: <ViewSalesAnimal />
        },
        {
            path: 'events/list',
            element: <EventsList />
        },
        {
            path: 'animals/edit/:id',
            element: <EditAnimal />
        },
        {
            path: 'app-user/profile/add-normal/:id',
            element: <AddAnimal />
        },
        {
            path: 'animals/pin/',
            element: <PinAnimal />
        },
        {
            path: 'notification/list',
            element: <AllNotificationList />
        },
        {
            path: 'settings',
            element: <Setting />
        },
        {
            path: 'enquiryform-templates/list',
            element: <EnquiryFormTemplates />
        },
        {
            path: 'enquiryform-templates/add',
            element: <AddEnquiryTemplate />
        },
        {
            path: 'enquiryform-templates/edit/:id',
            element: <EditEnquiryTemplate />
        },
        {
            path: 'pin/animals-breeders',
            element: <PinnedAnimals />
        },
        {
            path: 'pin/animals-breeders/list',
            element: <BreedersTable />
        },
        {
            path: 'pin/animals-breeders/pets-list',
            element: <AnimalsPetsIndex />
        },
        {
            path: 'pin/animals-breeders/livestock-list',
            element: <AnimalsLivestockIndex />
        },
        {
            path: ClubAssociationPaths.LIST,
            element: <ClubList />
        },
        {
            path: ClubAssociationPaths.ADD,
            element: <AddEditClub />
        },
        {
            path: ClubAssociationPaths.EDIT(),
            element: <AddEditClub />
        },
        {
            path: ClubAssociationPaths.VIEW(),
            element: <ClubDetails />
        },
        {
            path: AnimalTypePaths.LIST,
            element: <AnimalTypeList />
        },
        {
            path: AnimalTypePaths.VIEW(),
            element: <CategoryList />
        }
    ]
};

export default MainRoutes;
