/* eslint no-underscore-dangle: 0 */
import {
    Button,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { CategoryType } from 'types/breedManagement';
import { categoryHeadCells } from 'views/breedManagement/constants/variables';
import { useEffect, useState } from 'react';

type SubcategoryListTableProps = {
    subcategories: CategoryType[];
    search: string;
    handleView: (id: string, category: CategoryType) => void;
};

const SubcategoryListTable = ({ subcategories, search, handleView }: SubcategoryListTableProps) => {
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof CategoryType>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CategoryType) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        setPage(0);
    }, [search]);

    const filteredSubcategories = subcategories.filter((subcategory) => subcategory.name.toLowerCase().includes(search.toLowerCase()));

    const sortedSubcategories = filteredSubcategories.sort((a, b) => {
        if (orderBy === 'name') {
            return (a.name < b.name ? -1 : 1) * (order === 'asc' ? 1 : -1);
        }
        return 0;
    });

    const paginatedSubcategories = sortedSubcategories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {categoryHeadCells.map((headCell) => {
                                if (headCell.id === 'action') {
                                    return (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.align}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                        >
                                            <Box component="span">{headCell.label}</Box>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, headCell.id as keyof CategoryType)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {paginatedSubcategories.length > 0 &&
                            paginatedSubcategories.map((subcategory) => (
                                <TableRow key={subcategory._id}>
                                    <TableCell align="left">{subcategory.name}</TableCell>

                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                style={{ padding: '5px 10px' }}
                                                onClick={() => handleView(subcategory._id, subcategory)}
                                            >
                                                {subcategory.hasCategory ? 'View Subcategories' : 'View Breeds'}
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!subcategories.length && (
                    <Typography variant="h5" align="center" color="primary">
                        No data available
                    </Typography>
                )}

                <Pagination
                    count={Math.ceil(filteredSubcategories.length / rowsPerPage - 1)}
                    color="primary"
                    shape="rounded"
                    onChange={handleChangePage}
                    page={page === 0 ? 1 : page}
                    renderItem={(item) => <PaginationItem {...item} />}
                />
            </TableContainer>
        </>
    );
};

export default SubcategoryListTable;
