/* Club and association */
export const ClubAssociationPath = '/club-association';
export const ClubAssociationPaths = {
    LIST: `${ClubAssociationPath}/list`,
    ADD: `${ClubAssociationPath}/add`,
    EDIT: (id = ':id') => `${ClubAssociationPath}/edit/${id}`,
    VIEW: (id = ':id') => `${ClubAssociationPath}/view/${id}`
};

/* Animal Type */
export const AnimalTypePath = '/animal-type';
export const AnimalTypePaths = {
    LIST: `${AnimalTypePath}/list`,
    VIEW: (id = ':id') => `${AnimalTypePath}/view/${id}`
};
