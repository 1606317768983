import { gql } from '@apollo/client';

export const GET_ALL_SALES_ANIMALS = gql`
    query ($input: GetSalesAnimalListDTO!) {
        getSalesAnimalList(input: $input) {
            message
            salesAnimalList {
                _id
                listingName
                animalType
                breed
                gender
                price
                pureBred
                desexed
                createdAt
                media {
                    file
                    caption
                    isCover
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ALL_ANIMAL_TYPES = gql`
    query ($input: ListAnimalTypeInput!) {
        ListAnimalType(listAnimalTypeInput: $input) {
            message
            animalTypes {
                animalType
            }
        }
    }
`;

export const GET_ALL_ANIMAL_BREEDS = gql`
    query ($input: ListBreedInput!) {
        ListBreed(listBreedInput: $input) {
            message
            animalTypeDetail {
                animalType
                breeds
            }
        }
    }
`;
export const GET_SALES_ANIMALS = gql`
    query ($input: ViewSalesDetailPageInput!) {
        ViewSalesDetailPage(viewSalesDetailPageInput: $input) {
            message
            animal {
                service {
                    _id
                    listingType
                    displayName
                    adsDuration
                    price
                    descriptions
                    enquiryPreference {
                        value
                        type
                    }
                    expiryDate
                    expired
                }
                allServices {
                    _id
                    listingType
                    adsDuration
                    price
                    descriptions
                    enquiryPreference {
                        value
                        type
                    }
                    expiryDate
                    expired
                }
                _id
                userId
                animalId
                enquiryPreference {
                    enquiryValue
                    enquiryType
                }
                breederId
                adsDuration
                animalType
                breed
                animalCategory
                animalSubCategory
                animalTypeId
                breedId
                animalCategoryId
                animalSubCategoryId
                geneticTraits
                suitedFor
                chickensBantam
                handRaised
                adsDuration
                adsAmount
                expiryDate
                category
                gender
                desexed
                price
                listingName
                enquiryType
                enquiryValue
                showOnProfile
                description
                dateOfBirth
                dateOfBirthFormatted
                microchipNumber
                picNumber
                identificationNumber
                pureBred
                geneticReports
                achievements
                structuralReports
                customFields {
                    title
                    detail
                    files
                }
                media {
                    file
                    caption
                    isCover
                }
                status
                fatherId
                motherId
                offspringFormDetail {
                    mother {
                        listingName
                        image
                    }
                    father {
                        listingName
                        image
                    }
                }
            }
            fatherId
            father {
                _id
                media {
                    file
                    caption
                    isCover
                }
                listingName
            }
            fatherDetail {
                _id
                media {
                    file
                    caption
                    isCover
                }
                listingName
            }
            motherId
            mother {
                _id
                media {
                    file
                    caption
                    isCover
                }
                listingName
            }
            motherDetail {
                _id
                media {
                    file
                    caption
                    isCover
                }
                listingName
            }
            owner {
                address {
                    fullAddress
                    state
                    suburb
                    postCode
                }
                location {
                    coordinates
                }
            }
        }
    }
`;

export const ListNewAnimalTypeList = gql`
    query {
        ListNewAnimalType {
            message
            newAnimalTypes {
                _id
                name
                hasCategory
                category
                pricing {
                    month
                    price
                }
                extraFields {
                    name
                    value
                    hideOn
                    dataType
                    isRequired
                    displayName
                }
            }
        }
    }
`;

export const ListCategoriesAndBreeds = gql`
    query ($input: ListAllCategoriesAndBreedsInput!) {
        ListCategoriesAndBreeds(input: $input) {
            message
            categoriesAndBreeds {
                _id
                animalTypeId
                name
                hasCategory
                breeds {
                    name
                    _id
                }
                categories {
                    name
                    value
                    hideOn
                    dataType
                    isRequired
                }
            }
        }
    }
`;

export const getUsersAnimals = gql`
    query ($input: GetUsersAnimalsInput!) {
        getUsersAnimals(input: $input) {
            message
            salesAnimalList {
                _id
                listingName
                media {
                    file
                    caption
                    isCover
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;
