import * as Yup from 'yup';
import { SettingsEnum } from '../constants/enum';

const validationSchema = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            slug: Yup.string().required('Slug is required'),
            title: Yup.string().required('Title is required'),
            hide: Yup.boolean().required('Hide is required'),
            animalType: Yup.string().nullable(),
            feeLevels: Yup.array().when('slug', {
                is: (slug) => slug === SettingsEnum.TRANSACTION_FEES, // Apply validation only if slug is "transaction-fees"
                then: Yup.array()
                    .of(
                        Yup.object().shape({
                            min: Yup.string()
                                .required('Min value is required')
                                .test('is-valid-number', 'Min must be a valid number', (value) => !isNaN(Number(value)))
                                .test('is-positive', 'Min must be at least 0', (value) => Number(value) >= 0),
                            max: Yup.string()
                                .required('Max value is required')
                                .test('is-valid-number', 'Max must be a valid number', (value) => !isNaN(Number(value)))
                                .test('greater-than-min', 'Max must be greater than Min', function (value) {
                                    const { min } = this.parent;
                                    return Number(value) > Number(min);
                                }),
                            feePercentage: Yup.string()
                                .required('Fee Percentage is required')
                                .test('is-valid-number', 'Fee Percentage must be a valid number', (value) => !isNaN(Number(value)))
                                .test('in-range', 'Fee Percentage must be between 0 and 100', (value) => {
                                    const num = Number(value);
                                    return num >= 0 && num <= 100;
                                })
                        })
                    )
                    .required('Fee levels are required') // Ensure array exists
                    .min(1, 'At least one fee level is required'), // Ensure at least one entry exists
                otherwise: Yup.array().notRequired() // If not "transaction-fees", no validation is applied
            })
        })
    )
});

export default validationSchema;
