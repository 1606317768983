import { gql } from '@apollo/client';

export const ADD_BREED = gql`
    mutation AddBreed($input: CreateBreedDTO!) {
        addBreed(input: $input) {
            message
        }
    }
`;

export const UPDATE_BREED = gql`
    mutation UpdateBreed($input: UpdateBreedDTO!) {
        updateBreed(input: $input) {
            message
        }
    }
`;

export const DELETE_BREED = gql`
    mutation DeleteBreed($input: DeleteBreedDTO!) {
        deleteBreed(input: $input) {
            message
        }
    }
`;
