/* eslint-disable import/prefer-default-export */

import { HeadCell1 } from 'types';

// table header options
export const animalTypeHeadCells: HeadCell1[] = [
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        align: 'left',
        sort: true
    },
    {
        id: 'category',
        numeric: false,
        label: 'Category',
        align: 'left',
        sort: true
    },

    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];

export const categoryHeadCells: HeadCell1[] = [
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        align: 'left',
        sort: true
    },
    {
        id: 'action',
        numeric: false,
        label: 'Actions',
        align: 'right',
        sort: false
    }
];
